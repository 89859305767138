import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import GiftCategoryEditHeader from './components/GiftCategoryEditHeader';
import GiftCategoryEditForm from './components/GiftCategoryEditForm';
import { useParams } from 'react-router-dom';

export default function CategoryEdit() {
  const { themeStretch } = useSettings();
  const params = useParams();
  const id = params?.id;

  return (
    <Page title={id ? BREADCUMBS.GIFT_CATEGORY_EDIT : BREADCUMBS.GIFT_CATEGORY_CREATE}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <GiftCategoryEditHeader />
        <GiftCategoryEditForm />
      </Container>
    </Page>
  );
}
