import { useQuery } from 'react-query';
import { IParamsGiftPreOrderList } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getGiftPreOrdersV3 } from '../service';

export const useGetGiftPreOrders = (params: IParamsGiftPreOrderList) => {
  const dataQuery = useQuery(
    [QUERY_KEYS.GIFT_PRE_ORDER_LIST, params],
    () => getGiftPreOrdersV3(params),
    {
      cacheTime: 1000,
    }
  );
  return { ...dataQuery };
};
