import { useState } from 'react';
import { Container, Button, FormControlLabel, Switch, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import OrderShipHeader from './OrderShipHeader';
import { replacePathParams } from 'src/common/utils/replaceParams';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { usePatchShowShip } from '../common/hooks/usePatchShowShip';
import useMessage from 'src/common/hooks/useMessage';

const OrderEditShip: React.FC = () => {
  const navigate = useNavigate();
  const { showSuccessSnackbar, showErrorSnackbar } = useMessage();

  const { themeStretch } = useSettings();
  const { osOrderId, isShow } = useParams();
  const [checked, setChecked] = useState(isShow == '1' ? true : false);

  // Call the hook at the top level
  const { mutate: patchShowShip } = usePatchShowShip({
    onSuccess: () => {
      showSuccessSnackbar('Chỉnh sửa thành công');
      navigate(replacePathParams(PATH_DASHBOARD.orderAdmin.root, {}));
    },
    onError: () => {
      showErrorSnackbar('Chỉnh sửa thất bại');
    },
  });

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSave = () => {
    patchShowShip({
      isShowShippingFee: checked,
      osOrderId: String(osOrderId),
    });
  };

  const handleCancel = () => {
    navigate(replacePathParams(PATH_DASHBOARD.orderAdmin.root, {}));
  };

  return (
    <Page title={BREADCUMBS.ORDER_DETAIL}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <OrderShipHeader />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          mb={2}
          p={2}
          border={1}
          borderRadius={2}
          borderColor="grey.300"
          boxShadow={3}
        >
          <Box mb={2}>
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleSwitchChange} />}
              label="Hiển thị phí ship"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ mr: 2 }}
            >
              Lưu
            </Button>
            <Button variant="outlined" color="inherit" onClick={handleCancel}>
              Hủy
            </Button>
          </Box>
        </Box>
        {/* Add other components or content here */}
      </Container>
    </Page>
  );
};

export default OrderEditShip;
