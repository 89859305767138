import { Skeleton, TableCell, TableRow } from '@mui/material';

type Props = {
  row: number;
  column: number;
};

export default function LoadingTableSkeleton({ row, column }: Props) {
  return (
    <>
      {Array.from({ length: row }, (_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: column }, (_, colIndex) => (
            <TableCell align="left" key={colIndex}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
