import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  isShowReasonForm: boolean;
};

const initialState: InitialState = {
  isShowReasonForm: false,
};

const exchangePhoneNumberSlice = createSlice({
  name: 'exchangePhoneNumber',
  initialState,
  reducers: {
    setIsShowReasonForm: (state, action: PayloadAction<boolean>) => {
      state.isShowReasonForm = action.payload;
    },
  },
});

export const { setIsShowReasonForm } = exchangePhoneNumberSlice.actions;
export default exchangePhoneNumberSlice.reducer;
