import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import Iconify from 'src/common/components/Iconify';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { DEFAULT_VALUE_FILTER_FORM } from 'src/filter/common/constant';
import { setFilterDetail } from 'src/filter/common/slice';

export default function FilterListHeader() {
  const dispatch = useDispatch();

  return (
    <>
      <HeaderBreadcrumbs
        heading={BREADCUMBS.FILTER_MANAGE}
        links={[
          {
            name: BREADCUMBS.FILTER_MANAGE,
            href: PATH_DASHBOARD.filterAdmin.root,
          },
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={() => {
              localStorage.setItem(
                'filterDetail',
                JSON.stringify(DEFAULT_VALUE_FILTER_FORM)
              );
              dispatch(setFilterDetail(null));
            }}
            to={PATH_DASHBOARD.filterAdmin.filterCreate}
            component={RouterLink}
          >
            Tạo mới
          </Button>
        }
      />
    </>
  );
}
