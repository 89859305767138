import { helperText } from 'src/common/constants/common.constants';
import * as yup from 'yup';

export const filterFormSchema = yup.object().shape({
  name: yup.string().required(helperText.required),
  priority:yup
  .number()
  .typeError("Trường này phải là số") 
  .required(helperText.required)
  .moreThan(0, helperText.min),  
});
