import useSettings from '../../common/hooks/useSettings';
import Page from 'src/common/components/Page';
import { Container, Stack, Button } from '@mui/material';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import FilterDetailForm from './components/FilterDetailForm';
import FilterDetailHeader from './components/FilterDetailHeader';

export default function FilterDetail() {
  const { themeStretch } = useSettings();

  return (
    <Page title={'Chi tiết bộ lọc'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <FilterDetailHeader />
        <FilterDetailForm />
      </Container>
    </Page>
  );
}
