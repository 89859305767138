import { FC, useState } from 'react';
import { Box, Button, Menu, MenuItem, Divider } from '@mui/material';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import Iconify from 'src/common/components/Iconify';
import { BREADCUMBS, IMPORT_OPTION } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import useMessage from 'src/common/hooks/useMessage';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDialog from './dialog/FileDialog';
import LoadingDialog from './dialog/LoadingDialog';
import ErrorDialog from './dialog/ErrorDialog';
import { useImportCoin } from 'src/coin/common/hooks/useImportFile';
import { useNavigate } from 'react-router-dom';
import useTable from 'src/common/hooks/useTable';
import { useGetCoinList } from 'src/coin/common/hooks/useGetCoinList';

const CoinListHeader: FC<{ searchParams: any; refetch: any }> = ({
  searchParams,
  refetch,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedImportOption, setSelectedImportOption] = useState('');
  const [progress, setProgress] = useState(0);
  const [errorData, setErrorData] = useState([]);

  const { showSuccessSnackbar, showErrorSnackbar } = useMessage();

  const { mutate: updateMutate } = useImportCoin({
    onSuccess: (data) => {
      setOpenFileDialog(true);
      if (
        data.data?.response?.data?.failedCount > 0 ||
        data.data?.response?.data?.messages?.length > 1 ||
        (data.data?.response?.data?.messages?.length === 1 &&
          data.data?.response?.data?.messages?.[0]?.toLowerCase() !== 'ok')
      ) {
        setErrorData(data.data?.response?.data);
        setTimeout(() => {
          setOpenErrorDialog(true);
          setOpenFileDialog(false);
          showErrorSnackbar('Nhập file thất bại');
          refetch();
        }, 2000);
        return;
      }

      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              setOpenFileDialog(false);
              showSuccessSnackbar('Nhập file thành công');
              refetch();
            }, 2000);
            return 100;
          }
          const diff = Math.floor(Math.random() * 10) + 20;
          return Math.min(prevProgress + Math.random() * diff, 100);
        });
      }, 500);
    },

    onError: () => {
      setOpenFileDialog(true);
      setIsLoading(true);
      setTimeout(() => {
        setOpenFileDialog(false);
        setIsLoading(false);
        showErrorSnackbar('Nhập file thất bại');
        setOpenErrorDialog(true);
        refetch();
      }, 2000);
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setProgress(0); // Reset progress
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setProgress(0); // Reset progress
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setProgress(0); // Reset progress
  };

  const handleCloseFileDialog = () => {
    setOpenFileDialog(false);
    setProgress(0); // Reset progress
  };

  const handleCloseErrorDialog = () => {
    setProgress(0); // Reset progress
    setIsLoading(false);
    setOpenFileDialog(false);
    setOpenErrorDialog(false);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = `/assets/template/MVD.csv`;
    link.download = 'MVD.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    const value = event.currentTarget.getAttribute('value') || '';
    setSelectedImportOption(value);
    setOpenDialog(true);
    setProgress(0); // Reset progress
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    option: any
  ) => {
    event.preventDefault();

    let files: FileList | null = null;

    if ('dataTransfer' in event) {
      files = event.dataTransfer.files;
    } else if ('target' in event) {
      files = (event.target as HTMLInputElement).files;
    }

    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('file', files[0]);
      switch (option) {
        case IMPORT_OPTION.IMPORT_COIN_LIST:
          updateMutate(formData);
          break;

        default:
          break;
      }
    }

    handleCloseMenu();
    handleCloseDialog();
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading={BREADCUMBS.COIN_LIST}
        links={[
          {
            name: BREADCUMBS.COIN_MANAGE,
            href: PATH_DASHBOARD.coinAdmin.root,
          },
          { name: BREADCUMBS.COIN_LIST },
        ]}
        action={
          <>
            <Box marginRight="8px" marginLeft="8px" display="inline" textAlign="center">
              <Button
                variant="contained"
                startIcon={<Iconify icon={'mdi:file-import'} />}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClick}
              >
                Nhập
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={handleMenuItemClick}
                  value={IMPORT_OPTION.IMPORT_COIN_LIST}
                >
                  Danh sách hoàn xu
                </MenuItem>

                <Divider />
                <Button
                  variant="outlined"
                  startIcon={<Iconify icon={'mdi:download'} />}
                  onClick={handleDownloadTemplate}
                  sx={{ width: '90%', marginLeft: '10px', fontSize: '12px' }}
                >
                  Tải xuống template
                </Button>
              </Menu>
            </Box>
            <Button
              variant="contained"
              startIcon={<Iconify icon={'akar-icons:file'} />}
              onClick={() => {}}
            >
              Xuất
            </Button>
          </>
        }
      />
      <FileDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onImportFile={handleFileChange}
        option={selectedImportOption}
      />
      <LoadingDialog
        open={openFileDialog}
        onClose={handleCloseFileDialog}
        progress={progress}
        isLoading={isLoading}
        option={selectedImportOption}
      />
      <ErrorDialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        option={selectedImportOption}
        errors={errorData}
        onChooseFile={handleOpenDialog}
      />
    </>
  );
};

export default CoinListHeader;
