import { useQuery } from 'react-query';
import { IParamsFiltersList } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getFilterList } from '../service';

export const useGetFilters = (params: IParamsFiltersList) => {
  const dataQuery = useQuery(
    [QUERY_KEYS.GIFT_CATEGORY_LIST, params],
    () => getFilterList(params),
    {
      cacheTime: 3000,
    }
  );
  return { ...dataQuery };
};
