import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

export default function GiftCategoryEditHeader() {
  const params = useParams();
  const id = params?.id;
  return (
    <>
      <HeaderBreadcrumbs
        heading={id ? BREADCUMBS.GIFT_CATEGORY_EDIT : BREADCUMBS.GIFT_CATEGORY_CREATE}
        links={[
          {
            name: BREADCUMBS.GIFT_CATEGORY,
            href: PATH_DASHBOARD.giftAdmin.categoryList,
          },
          { name: id ? BREADCUMBS.GIFT_CATEGORY_EDIT : BREADCUMBS.GIFT_CATEGORY_CREATE },
        ]}
      />
    </>
  );
}
