import { Can } from '@casl/react';
import { TableCell, MenuItem, TableRow, Switch, Checkbox } from '@mui/material';
import { useState } from 'react';
import { TableMoreMenu } from 'src/common/components/table';
import useTable from 'src/common/hooks/useTable';
import { useGetFilters } from 'src/filter/common/hooks/useGetFilters';
import { IParamsFiltersList } from 'src/filter/common/interface';
import { editFilter } from 'src/filter/common/service';
import { GiftCategory } from 'src/gift/common/interface';

type Props = {
  row: any;
  onDeleteRow: VoidFunction;
  onEditRow: VoidFunction;
  onDetailRow: VoidFunction;
  selected: boolean;
  onSelectRow: (checked: boolean) => void;
  index: number;
};

export default function FilterRow({
  row,
  onEditRow,
  onSelectRow,
  onDetailRow,
  selected,
  index,
}: Props) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const propsTable = useTable({
    defaultRowsPerPage: 50,
  });
  const [searchParams, setSearchParams] = useState<IParamsFiltersList>({
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });
  const { refetch } = useGetFilters({
    ...searchParams,
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });
  const [dataRow, setDataRow] = useState<GiftCategory>(row);
  const { dynamicData, code, priority, isActive } = dataRow;

  const handleOpenMenu = (category: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(category.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleOnChangeStatus = async (active: boolean) => {
    try {
      const res = await editFilter({ ...dataRow, isActive: active });
      if (res) {
        setDataRow({ ...dataRow, isActive: active });
        refetch();
      }
    } catch (error) {
      setDataRow({ ...dataRow, isActive: !active });
      console.error('Failed to update status:', error);
    }
  };

  return (
    <TableRow>
      {/* <TableCell padding="checkbox" align="left">
        <Checkbox checked={selected} onChange={(e) => onSelectRow(e.target.checked)} />
      </TableCell> */}

      <TableCell align="left">{index}</TableCell>

      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">
        {row.value && Array.isArray(row.value)
          ? row.value.map((item: any) => item.name).join(', ')
          : ''}
      </TableCell>

      <TableCell align="left">{row.priority}</TableCell>

      <TableCell align="left">
        <Switch
          size="medium"
          checked={isActive}
          onChange={(e) => handleOnChangeStatus(e.target.checked)}
        />
      </TableCell>
      <TableCell align="center">
        <TableMoreMenu
          open={openMenu}
          onClose={handleCloseMenu}
          onOpen={handleOpenMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDetailRow();
                  handleCloseMenu();
                }}
              >
                Xem chi tiết
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                Chỉnh sửa
              </MenuItem>
            </>
          }
        ></TableMoreMenu>
      </TableCell>
    </TableRow>
  );
}
