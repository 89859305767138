import {
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from 'src/common/components/table';
import LoadingTableSkeleton from 'src/common/components/LoadingTableSkeleton';
import { useSelectMultiple } from 'src/common/hooks/useSelectMultiple';
import Iconify from 'src/common/components/Iconify';
import { ConfirmModal } from 'src/common/components/modal/ConfirmModal';
import { FC, useEffect, useState } from 'react';
import useShowSnackbar from 'src/common/hooks/useMessage';
import { useQueryClient } from 'react-query';
import { replacePathParams } from 'src/common/utils/replaceParams';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { TABLE_HEAD_FILTER_LIST } from 'src/filter/common/constant';
import { useDeleteGift } from 'src/gift/common/hooks/useDeleteGift';
import { useDispatch } from 'react-redux';
import { setFilterDetail } from 'src/filter/common/slice';
import FilterRow from './FilterRow';

export interface FilterListTableProps {
  data: any;
  // data: IResGiftCategoryList | undefined;
  isLoading: boolean;
  dense: boolean;
  order: any;
  page: number;
  setPage: any;
  orderBy: any;
  rowsPerPage: any;
  selected: any;
  setSelected: any;
  onSelectRow: any;
  onSelectAllRows: any;
  onSort: any;
  onChangePage: any;
  onChangeDense: any;
  onChangeRowsPerPage: any;
}

const FilterListTable: FC<FilterListTableProps> = ({
  data,
  isLoading,
  page,
  rowsPerPage,
  dense,
  onChangeDense,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [idsDelete, setIdsDelete] = useState<string[]>([]);

  const { showErrorSnackbar, showSuccessSnackbar } = useShowSnackbar();
  const queryClient = useQueryClient();

  const { mutate } = useDeleteGift();

  const listGiftCategories = data || [];
  const totalItems = 0;

  const {
    isCheckedAll,
    reset: resetSelect,
    selectedIds,
    handleSelectItem,
    handleCheckAll,
  } = useSelectMultiple<string>(
    listGiftCategories.map((item: any) => item.code),
    page + 1
  );

  const handleDeleteMultiple = (selectedIds: string[]) => {
    setIdsDelete(selectedIds);
    setIsOpenModal(true);
  };

  const onDeleteMultiple = () => {
    // idsDelete.forEach((code) =>
    //   mutate(
    //     { code },
    //     {
    //       onSuccess: (res) => {
    //         if (res?.data?.meta?.status === 1000) {
    //           showSuccessSnackbar(`Xóa category thành công`);
    //           queryClient.invalidateQueries();
    //         } else showErrorSnackbar(`Xóa category thất bại`);
    //       },
    //       onError: () => {
    //         showErrorSnackbar(`Xóa category thất bại`);
    //       },
    //     }
    //   )
    // );
    resetSelect();
  };

  const handleEditRow = (data: any) => {
    localStorage.setItem('filterDetail', JSON.stringify(data));
    dispatch(setFilterDetail(data));
    navigate(
      replacePathParams(PATH_DASHBOARD.filterAdmin.filterEdit, { id: data?.code })
    );
  };

  const handleDetailRow = (data: any) => {
    localStorage.setItem('filterDetail', JSON.stringify(data));
    dispatch(setFilterDetail(data));
    navigate(
      replacePathParams(PATH_DASHBOARD.filterAdmin.filterDetail, { id: data?.code })
    );
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <ConfirmModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={onDeleteMultiple}
        type={'delete'}
        text={'Bạn có chắc muốn xóa bộ lọc này?'}
      />
      <TableContainer sx={{ position: 'relative' }}>
        {!!selectedIds.length && (
          <TableSelectedActions
            dense={dense}
            isSelectAll={isCheckedAll}
            numSelected={selectedIds.length}
            rowCount={listGiftCategories.length}
            onSelectAllRows={handleCheckAll}
            actions={
              <Tooltip title="Delete">
                <IconButton
                  color="primary"
                  onClick={() => {
                    handleDeleteMultiple(selectedIds);
                  }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                </IconButton>
              </Tooltip>
            }
          />
        )}
        <Table size={dense ? 'small' : 'medium'}>
          <TableHeadCustom
            headLabel={TABLE_HEAD_FILTER_LIST}
            rowCount={listGiftCategories.length}
            isSelectAll={isCheckedAll}
            numSelected={selectedIds.length}
            onSelectAllRows={handleCheckAll}
          />
          <TableBody>
            {listGiftCategories
              .sort((a: any, b: any) => b.name.localeCompare(a.name))
              .map((row: any, index: number) => (
                <FilterRow
                  key={row.id}
                  index={index + 1}
                  row={row}
                  onEditRow={() => handleEditRow(row)}
                  onDeleteRow={() => handleDeleteMultiple([row.code])}
                  onDetailRow={() => handleDetailRow(row)}
                  selected={selectedIds.includes(row.code)}
                  onSelectRow={(e) => {
                    handleSelectItem(row.code, e);
                  }}
                />
              ))}

            {isLoading && (
              <LoadingTableSkeleton
                row={rowsPerPage}
                column={TABLE_HEAD_FILTER_LIST.length + 1}
              />
            )}
            <TableNoData isNotFound={!isLoading && !listGiftCategories?.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          // rowsPerPageOptions={[5, 10, 15]}
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={lodash.isEmpty(totalItems) ? (totalItems as number) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label={'Thu gọn'}
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>
    </Paper>
  );
};
export default FilterListTable;
