import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const ErrorListBox = styled(Box)({
  maxHeight: '200px',
  overflowY: 'auto',
  padding: '8px',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
});

interface ErrorDialogProps {
  open: boolean;
  option: string;
  onClose: () => void;
  errors: any;
  onChooseFile: () => void;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  open,
  option,
  onClose,
  errors,
  onChooseFile,
}) => {
  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = `/assets/template/MVD.csv`;
    link.download = 'MVD.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box position="relative">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>{option}</DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'left' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <svg
              width="22"
              height="19"
              viewBox="0 0 22 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5591 14.3002L13.8891 1.58018C13.2588 0.594182 12.1694 -0.00244141 10.9991 -0.00244141C9.82889 -0.00244141 8.73941 0.594182 8.10912 1.58018L0.439124 14.3002C-0.11233 15.2194 -0.131391 16.3631 0.389124 17.3002C0.991098 18.3553 2.11435 19.0048 3.32912 19.0002H18.6691C19.8756 19.0131 20.997 18.3799 21.6091 17.3402C22.1452 16.3928 22.1261 15.2294 21.5591 14.3002ZM10.9991 15.0002C10.4468 15.0002 9.99912 14.5525 9.99912 14.0002C9.99912 13.4479 10.4468 13.0002 10.9991 13.0002C11.5514 13.0002 11.9991 13.4479 11.9991 14.0002C11.9991 14.5525 11.5514 15.0002 10.9991 15.0002ZM10.9991 12.0002C11.5514 12.0002 11.9991 11.5525 11.9991 11.0002V7.00018C11.9991 6.4479 11.5514 6.00018 10.9991 6.00018C10.4468 6.00018 9.99912 6.4479 9.99912 7.00018V11.0002C9.99912 11.5525 10.4468 12.0002 10.9991 12.0002Z"
                fill="#EE4549"
              />
            </svg>

            <Typography
              variant="h6"
              color="error"
              style={{ marginLeft: '10px', marginTop: '4px' }}
            >
              Lỗi dữ liệu
            </Typography>
          </div>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Vui lòng kiểm tra và cập nhật lại file, hoặc{' '}
            <a
              href="#"
              onClick={handleDownloadTemplate}
              style={{ color: 'blue', cursor: 'pointer' }}
            >
              tải xuống template
            </a>{' '}
            có sẵn để sử dụng.
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Tổng cộng: {errors?.insertedCount} dòng, lỗi dữ liệu: {errors?.messages?.length} lỗi.
          </Typography>
          <ErrorListBox sx={{ mt: 2 }}>
            {errors?.messages &&
              errors?.messages?.map?.((error: string, index: number) => (
                <Typography key={index} variant="body2" color="error">
                  {error}
                  {/* Dòng #{error.row}:{' '}
                  {error.fields.map((field: any, idx: number) => (
                    <span key={idx} style={{ color: '#FF0000' }}>
                      &lt;{field.name}&gt; {field.message}
                    </span>
                  ))} */}
                </Typography>
              ))}
          </ErrorListBox>
        </Box>
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <div>
            <Button
              variant="outlined"
              color="inherit"
              onClick={onClose}
              sx={{ mr: 1, backgroundColor: '#919EAB29' }}
            >
              Hủy bỏ
            </Button>
            <Button
              variant="outlined"
              sx={{ backgroundColor: '#00AB55', color: '#FFFFFF' }}
              onClick={() => {
                onClose();
                onChooseFile();
              }}
            >
              Chọn file khác
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
