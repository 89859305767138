import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Paper,
  Stack,
  Switch,
  Typography,
  Collapse,
  Button,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/common/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useShowSnackbar from 'src/common/hooks/useMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { giftCategoryFormSchema } from 'src/gift/common/schema';
import { DEFAULT_VALUE_FILTER_FORM } from 'src/filter/common/constant';
import { IGiftCategoryForm } from 'src/gift/common/interface';

import { AddOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectFilterDetail } from 'src/filter/common/slice';
import { createFilterDetail } from 'src/filter/common/service';
import { useCreateFilterDetail } from 'src/filter/common/hooks/useCreateFilterDetail';

function convertText(input: string): string {
  const normalizedText = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const upperCaseText = normalizedText.toUpperCase();
  const finalText = upperCaseText.replace(/[^A-Z0-9]/g, '_');
  return finalText;
}

export default function FilterDetailForm() {
  const [items, setItems] = useState<any[]>([]);
  const [newItem, setNewItem] = useState<string>('');
  const [firstLevelText, setfirstLevelText] = useState<string>('');
  const [secondLevelText, setSecondLevelText] = useState<string>('');
  const [thirdLevelText, setThirdLevelText] = useState<string>('');
  const [fourthLevelText, setFourthLevelText] = useState<string>('');
  const [editItemIndex, setEditItemIndex] = useState<any | null>(null);
  const [editfirstLevelIndex, setEditfirstLevelIndex] = useState<any | null>(null);
  const [editThirdLevelIndex, setEditThirdLevelIndex] = useState<any | null>(null);

  const filterDetail = useSelector(selectFilterDetail);
  const methods = useForm<IGiftCategoryForm>({
    resolver: yupResolver(giftCategoryFormSchema),
    defaultValues: DEFAULT_VALUE_FILTER_FORM,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = methods;
  const { showSuccessSnackbar, showErrorSnackbar } = useShowSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleAddFilterMain = () => {
    if (newItem.trim()) {
      setItems([
        ...items,
        {
          name: newItem,
          isActive: true,
          code: convertText(newItem),
          firstLevel: [],
        },
      ]);
      setNewItem('');
    }
  };

  const handleDeleteFilterMain = (index: any) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleAddfilterSub = (itemIndex: any) => {
    if (firstLevelText.trim()) {
      const newItems = [...items];
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        firstLevel: [
          ...newItems[itemIndex].firstLevel,
          {
            name: firstLevelText,
            isActive: true,
            code: convertText(firstLevelText),
            value: [],
          },
        ],
      };
      setItems(newItems);
      setfirstLevelText('');
    }
  };

  const handleDeletefilterSub = (itemIndex: any, firstLevelIndex: any) => {
    const newItems = [...items];
    newItems[itemIndex].firstLevel = newItems[itemIndex].firstLevel.filter(
      (_: any, i: any) => i !== firstLevelIndex
    );
    setItems(newItems);
  };

  const handleAddFilterSubvalue = (itemIndex: number, firstLevelIndex: number) => {
    if (secondLevelText.trim()) {
      const newItems = [...items]; // Shallow copy of the items array

      // Create a new object for the firstLevel array
      const updatedFirstLevel = newItems[itemIndex].firstLevel.map(
        (firstLevelItem: any, index: any) => {
          if (index !== firstLevelIndex) return firstLevelItem;

          // Create a new value array with the added item
          const updatedValue = [
            ...firstLevelItem.value,
            {
              name: secondLevelText,
              isActive: true,
              code: convertText(secondLevelText),
              secondLevel: [],
            },
          ];

          // Return a new firstLevelItem with updated value
          return {
            ...firstLevelItem,
            value: updatedValue,
          };
        }
      );

      // Create a new item with the updated firstLevel array
      const updatedItems = newItems.map((item, index) => {
        if (index !== itemIndex) return item;

        return {
          ...item,
          firstLevel: updatedFirstLevel,
        };
      });

      setItems(updatedItems); // Set the new items state
      setSecondLevelText(''); // Clear the secondLevelText
    }
  };
  const handleDeleteFilterSubvalue = (
    itemIndex: any,
    firstLevelIndex: any,
    secondLevelIndex: any
  ) => {
    const newItems = [...items];
    newItems[itemIndex].firstLevel[firstLevelIndex].value = newItems[
      itemIndex
    ].firstLevel[firstLevelIndex].value.filter(
      (_: any, i: any) => i !== secondLevelIndex
    );
    setItems(newItems);
  };

  const handleAddNameSmallFilter = (
    itemIndex: number,
    firstLevelIndex: number,
    secondLevelIndex: number
  ) => {
    if (thirdLevelText.trim()) {
      setItems((prevItems) => {
        const newItems = JSON.parse(JSON.stringify(prevItems));
        const targetItem =
          newItems[itemIndex]?.firstLevel[firstLevelIndex]?.value[secondLevelIndex];

        if (targetItem) {
          if (!Array.isArray(targetItem.secondLevel)) {
            targetItem.secondLevel = [];
          }

          targetItem.secondLevel = [
            ...targetItem.secondLevel,
            {
              name: thirdLevelText,
              isActive: true,
              code: convertText(thirdLevelText),
              value: [],
            },
          ];
        }

        return newItems;
      });

      setThirdLevelText('');
    }
  };
  const handleDeleteNameSmallFilter = (
    itemIndex: any,
    firstLevelIndex: any,
    secondLevelIndex: any,
    thirdLevelIndex: any
  ) => {
    const newItems = [...items];
    newItems[itemIndex].firstLevel[firstLevelIndex].value[secondLevelIndex].value =
      newItems[itemIndex].firstLevel[firstLevelIndex].value[
        secondLevelIndex
      ].value.filter((_: any, i: any) => i !== thirdLevelIndex);
    setItems(newItems);
  };

  const handleAddValueSmallFilter = (
    itemIndex: any,
    firstLevelIndex: any,
    secondLevelIndex: any,
    thirdLevelIndex: any
  ) => {
    if (fourthLevelText.trim()) {
      setItems((prevItems) => {
        const newItems = JSON.parse(JSON.stringify(prevItems));
        const targetItem =
          newItems[itemIndex]?.firstLevel[firstLevelIndex]?.value[secondLevelIndex]
            .secondLevel[thirdLevelIndex];

        if (targetItem) {
          if (!Array.isArray(targetItem.value)) {
            targetItem.value = [];
          }

          targetItem.value = [
            ...targetItem.value,
            {
              name: fourthLevelText,
              isActive: true,
              code: convertText(fourthLevelText),
            },
          ];
        }

        return newItems;
      });
    }
    setFourthLevelText('');
  };

  const { mutate: postFilter } = useCreateFilterDetail({
    onSuccess: () => {
      showSuccessSnackbar('Cập nhật bộ lọc thành công');
      navigate(PATH_DASHBOARD.filterAdmin.root);
    },

    onError: () => {
      showErrorSnackbar('Cập nhật bộ lọc thất bại');
      navigate(PATH_DASHBOARD.filterAdmin.root);
    },
  });

  const onSubmit = async (data: any) => {
    const payload = {
      name: data.name,
      code: convertText(data.name),
      isActive: data.isActive,
      priority: parseInt(data.priority, 10),
      value: items,
    };
    postFilter(payload);
  };

  useEffect(() => {
    if (filterDetail) {
      const { priority, isActive, name, code, value } = filterDetail;
      setItems([...(value || [])]);
      reset({ name, priority, isActive, code });
    }
  }, [filterDetail, reset]);

  function convertToBoolean(value: string | boolean | undefined): boolean {
    // Handle the case when value is undefined
    if (value === undefined) return false;
    
    // Handle boolean values directly
    if (typeof value === 'boolean') return value;
  
    // Handle string values
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }
  
    // Default case: return false for any other types
    return false;
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 2 }}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={3} paddingTop="20px">
            <Stack width="70%">
              <RHFTextField
                name="name"
                disabled
                label="Tên bộ lọc chính *"
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    py: 0.75,
                    fontSize: '14px',
                  },
                }}
              />
            </Stack>
            <Stack width="20%">
              <RHFTextField
                disabled
                name="priority"
                label="Priority *"
                type="any"
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    py: 0.75,
                    fontSize: '14px',
                  },
                }}
              />
            </Stack>
            <Stack width="10%" direction={'row'} alignItems={'center'} spacing={1}>
              <Typography fontSize="14px" style={{ fontWeight: 'bold' }}>
                Active
              </Typography>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <Switch disabled {...field} checked={convertToBoolean(field.value)} size="small" />
                )}
              />
            </Stack>
          </Stack>
        </Stack>

        <div style={{ padding: '20px' }}>
          <List>
            {items.map((item, itemIndex) => (
              <Paper
                key={itemIndex}
                style={{ marginBottom: 10 }}
                elevation={6}
                sx={{ p: 4, borderRadius: 2 }}
              >
                <ListItem>
                  <RHFTextField
                    disabled
                    value={item.name}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[itemIndex].name = e.target.value;
                      setItems(newItems);
                    }}
                    name={`mainFilter${itemIndex}`}
                    label="Giá trị bộ lọc chính"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-root': {
                        py: 0.75,
                        fontSize: '14px',
                      },
                    }}
                  />
                </ListItem>
                <Collapse in={editItemIndex === itemIndex} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.firstLevel.map(
                      (
                        firstLevel: {
                          name: unknown;
                          isActive: boolean | undefined;
                          value: any[];
                        },
                        firstLevelIndex:
                          | string
                          | any
                          | ((prevState: any | null) => any | null)
                          | null
                          | undefined
                      ) => (
                        <Paper
                          key={firstLevelIndex}
                          style={{ marginBottom: 10, marginLeft: 20 }}
                          elevation={3}
                          sx={{ p: 2, borderRadius: 2 }}
                        >
                          <ListItem>
                            <RHFTextField
                              disabled
                              value={firstLevel.name}
                              onChange={(e) => {
                                const newItems = [...items];
                                newItems[itemIndex].firstLevel[firstLevelIndex].name =
                                  e.target.value;
                                setItems(newItems);
                              }}
                              name={`firstLevelFilter${itemIndex}-${firstLevelIndex}`}
                              label="Tên bộ lọc phụ"
                              InputLabelProps={{ shrink: true }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  py: 0.75,
                                  fontSize: '14px',
                                },
                              }}
                            />
                            <Typography
                              fontSize="14px"
                              style={{ fontWeight: 'bold', marginLeft: 10 }}
                            >
                              Active
                            </Typography>

                            <Switch
                              disabled
                              checked={convertToBoolean(firstLevel.isActive)}
                              size="small"
                            />
                          </ListItem>
                          <Collapse
                            in={editfirstLevelIndex === firstLevelIndex}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {firstLevel.value &&
                                firstLevel.value.map(
                                  (
                                    secondLevel: {
                                      name: unknown;
                                      isActive: boolean | undefined;
                                      secondLevel: {
                                        name: unknown;
                                        isActive: boolean | undefined;
                                        value: any[];
                                      }[];
                                    },
                                    secondLevelIndex: React.Key | number | 0
                                  ) => (
                                    <Paper
                                      key={secondLevelIndex}
                                      style={{ marginBottom: 10, marginLeft: 20 }}
                                      elevation={2}
                                      sx={{ p: 2, borderRadius: 2 }}
                                    >
                                      <ListItem>
                                        <RHFTextField
                                          value={secondLevel.name}
                                          onChange={(e) => {
                                            // const newItems = [...items];
                                            // newItems[itemIndex].firstLevel[
                                            //   firstLevelIndex
                                            // ].value[secondLevelIndex].name = e.target.value;
                                            // setItems(newItems);
                                          }}
                                          disabled
                                          name={`secondLevelFilter${itemIndex}-${firstLevelIndex}-${secondLevelIndex}`}
                                          label="Giá trị bộ lọc phụ"
                                          InputLabelProps={{ shrink: true }}
                                          sx={{
                                            '& .MuiInputBase-root': {
                                              py: 0.75,
                                              fontSize: '14px',
                                            },
                                          }}
                                        />
                                      </ListItem>
                                      <List component="div" disablePadding>
                                        {secondLevel?.secondLevel &&
                                          secondLevel.secondLevel.map(
                                            (
                                              thirdLevel: {
                                                name: unknown;
                                                isActive: boolean | undefined;
                                                value: any[];
                                              },
                                              thirdLevelIndex:
                                                | string
                                                | any
                                                | ((prevState: any | null) => any | null)
                                                | null
                                                | undefined
                                            ) => (
                                              <Paper
                                                key={thirdLevelIndex?.toString()}
                                                style={{
                                                  marginBottom: 10,
                                                  marginLeft: 20,
                                                }}
                                                elevation={1}
                                                sx={{ p: 2, borderRadius: 2 }}
                                              >
                                                <ListItem>
                                                  <RHFTextField
                                                    value={thirdLevel.name}
                                                    disabled
                                                    name={`thirdLevelFilter${itemIndex}-${firstLevelIndex}-${secondLevelIndex}-${thirdLevelIndex}`}
                                                    label="Tên bộ lọc nhỏ"
                                                    InputLabelProps={{ shrink: true }}
                                                    sx={{
                                                      '& .MuiInputBase-root': {
                                                        py: 0.75,
                                                        fontSize: '14px',
                                                      },
                                                    }}
                                                  />
                                                  <Typography
                                                    fontSize="14px"
                                                    style={{
                                                      fontWeight: 'bold',
                                                      marginLeft: 10,
                                                    }}
                                                  >
                                                    Active
                                                  </Typography>

                                                  <Switch
                                                    disabled
                                                    checked={convertToBoolean(thirdLevel.isActive)}
                                                    size="small"
                                                  />
                                                </ListItem>
                                                <Collapse
                                                  in={
                                                    editThirdLevelIndex ===
                                                    thirdLevelIndex
                                                  }
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <List component="div" disablePadding>
                                                    {thirdLevel.value.map(
                                                      (fourthLevel, fourthLevelIndex) => (
                                                        <ListItem
                                                          key={fourthLevelIndex}
                                                          style={{ paddingLeft: '30px' }}
                                                        >
                                                          <RHFTextField
                                                            value={fourthLevel.name}
                                                            disabled
                                                            name={`fourthLevelFilter${itemIndex}-${firstLevelIndex}-${secondLevelIndex}-${thirdLevelIndex}-${fourthLevelIndex}`}
                                                            label="Giá trị bộ lọc nhỏ"
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                            sx={{
                                                              '& .MuiInputBase-root': {
                                                                py: 0.75,
                                                                fontSize: '14px',
                                                              },
                                                            }}
                                                          />
                                                        </ListItem>
                                                      )
                                                    )}
                                                  </List>
                                                </Collapse>
                                                <Button
                                                  style={{ marginTop: 10 }}
                                                  onClick={() =>
                                                    setEditThirdLevelIndex(
                                                      editThirdLevelIndex ===
                                                        thirdLevelIndex
                                                        ? null
                                                        : thirdLevelIndex
                                                    )
                                                  }
                                                  variant="outlined"
                                                >
                                                  {editThirdLevelIndex === thirdLevelIndex
                                                    ? 'Đóng bộ lọc nhỏ'
                                                    : 'Hiển thị bộ lọc nhỏ'}
                                                </Button>
                                              </Paper>
                                            )
                                          )}
                                      </List>
                                    </Paper>
                                  )
                                )}
                            </List>
                          </Collapse>
                          <Button
                            style={{ marginTop: 10 }}
                            onClick={() =>
                              setEditfirstLevelIndex(
                                editfirstLevelIndex === firstLevelIndex
                                  ? null
                                  : firstLevelIndex
                              )
                            }
                            variant="outlined"
                          >
                            {editfirstLevelIndex === firstLevelIndex
                              ? 'Đóng bộ lọc phụ'
                              : 'Hiển thị bộ lọc phụ'}
                          </Button>
                        </Paper>
                      )
                    )}
                  </List>
                </Collapse>
                <Divider />
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() =>
                    setEditItemIndex(editItemIndex === itemIndex ? null : itemIndex)
                  }
                  variant="outlined"
                >
                  {editItemIndex === itemIndex
                    ? 'Đóng bộ lọc chính'
                    : 'Hiển thị bộ lọc chính'}
                </Button>
              </Paper>
            ))}
          </List>
        </div>
        {/* <Stack spacing={2}>
          <Stack alignSelf={'flex-end'} mt={1}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              loading={isSubmitting}
            >
              {id ? 'Lưu' : 'Tạo mới'}
            </LoadingButton>
          </Stack>
        </Stack> */}
      </Paper>
    </FormProvider>
  );
}
