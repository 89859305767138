import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Filter, StateProps } from './interface';
import { RootState } from 'src/common/redux/store';
const filterDetailString = localStorage.getItem('filterDetail');
const filterDetail = filterDetailString ? JSON.parse(filterDetailString) : null;
export const initialState: StateProps = {
  keySearch: '',
  filters: [],
  filterDetail: filterDetail,
};

export const manageGiftSlice = createSlice({
  name: 'manage-filter',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload;
    },
    setKeySearch: (state, action: PayloadAction<string>) => {
      state.keySearch = action.payload;
    },
    setFilterDetail: (state, action: PayloadAction<any>) => {
      state.filterDetail = action.payload;
    },
  },
});

export const { setFilters, setKeySearch, setFilterDetail } = manageGiftSlice.actions;

export const selectFilters = (state: RootState) => state.filter.filters;
export const selectKeySearch = (state: RootState) => state.filter.keySearch;
export const selectFilterDetail = (state: RootState) => state.filter.filterDetail;

export default manageGiftSlice.reducer;
