import { useQuery } from 'react-query';
import { IParamsGiftCategoriesList } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getGiftCategoriesV3 } from '../service';

export const useGetGiftCategories = (params: IParamsGiftCategoriesList) => {
  const dataQuery = useQuery(
    [QUERY_KEYS.GIFT_CATEGORY_LIST, params],
    () => getGiftCategoriesV3(params),
    {
      cacheTime: 1000,
    }
  );
  return { ...dataQuery };
};
