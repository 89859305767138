import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import HiddenTagEditHeader from './components/HiddenTagEditHeader';
import HiddenTagEditForm from './components/HiddenTagEditForm';
import { useParams } from 'react-router-dom';

export default function HiddenTagEdit() {
  const { themeStretch } = useSettings();
  const params = useParams();
  const id = params?.id;

  return (
    <Page title={id ? BREADCUMBS.HIDDEN_TAG_EDIT : BREADCUMBS.HIDDEN_TAG_CREATE}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HiddenTagEditHeader />
        <HiddenTagEditForm />
      </Container>
    </Page>
  );
}
