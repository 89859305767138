import { useMutation, useQueryClient } from 'react-query'; // Import queryClient from react-query

import { createFilterDetail } from '../service';
import { useGetFilters } from './useGetFilters';
import useTable from 'src/common/hooks/useTable';

import { IOrderAdminCallback } from 'src/orders/common/interface';

export const useCreateFilterDetail = (callback: IOrderAdminCallback) => {
  const propsTable = useTable();

  const { refetch } = useGetFilters({
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });

  return useMutation(createFilterDetail, {
    onSuccess: async (rs, _variables) => {
      callback.onSuccess && callback.onSuccess(rs);
      refetch();
    },
    onError: (error, _variables) => {
      callback.onError && callback.onError(error);
    },
  });
};
