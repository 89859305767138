import { helperText } from 'src/common/constants/common.constants';
import * as yup from 'yup';

export const giftCategoryFormSchema = yup.object().shape({
  name: yup.string().required(helperText.required),
  code: yup.string().required(helperText.required),
  priority: yup
    .number()
    .typeError(helperText.required)
    .required(helperText.required)
    .min(1, 'Priority phải lớn hơn 0'),
});
