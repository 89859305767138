import { Button } from '@mui/material';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { replacePathParams } from 'src/common/utils/replaceParams';

export default function FilterDetailHeader() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <HeaderBreadcrumbs
        heading={BREADCUMBS.FILTER_MANAGE}
        links={[
          {
            name: BREADCUMBS.FILTER_MANAGE,
            href: PATH_DASHBOARD.filterAdmin.root,
          },
          { name: BREADCUMBS.FILTER_DETAIL },
        ]}
        action={
          <Button
            variant="contained"
            onClick={() => {   
              // console.log(id);
                         
              navigate(
                replacePathParams(PATH_DASHBOARD.filterAdmin.filterEdit, { id: id })
              );
            }}
          >
            Chỉnh sửa
          </Button>
        }
      />
    </>
  );
}
