import { FC } from 'react';
import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import lodash from 'lodash';
import { TableHeadCustom, TableNoData } from 'src/common/components/table';
import LoadingTableSkeleton from 'src/common/components/LoadingTableSkeleton';
import { useSelectMultiple } from 'src/common/hooks/useSelectMultiple';
import { TABLE_HEAD_COIN_LIST } from 'src/coin/common/constant';
import { ICoinListTableProps } from 'src/coin/common/interface';
import CoinListRow from './CoinListRow';

const CoinListTable: FC<ICoinListTableProps> = ({
  data,
  isLoading,
  page,
  rowsPerPage,
  dense,
  onChangeDense,
  onChangePage,
  onChangeRowsPerPage,
  totalItems = 1,
  renderCount,
}) => {
  console.log('CoinListTable rendered, renderCount:', renderCount); // Confirm renderCount in console

  const coinList = data || [];

  const {
    isCheckedAll,
    reset: resetSelect,
    selectedIds,
    handleSelectItem,
    handleCheckAll,
  } = useSelectMultiple<number>(
    coinList.map((item: any) => item.id),
    page + 1
  );

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <TableContainer sx={{ position: 'relative' }}>
        <Table size={dense ? 'small' : 'medium'}>
          <TableHeadCustom
            headLabel={TABLE_HEAD_COIN_LIST}
            rowCount={coinList.length}
            isSelectAll={isCheckedAll}
            numSelected={selectedIds.length}
            onSelectAllRows={handleCheckAll}
          />
          <TableBody>
            {coinList.map((row: any) => (
              <CoinListRow
                key={row.id}
                row={row}
                selected={selectedIds.includes(row.id)}
                onSelectRow={(e: any) => {
                  handleSelectItem(row.id, e);
                }}
                onDeleteRow={() => {}}
              />
            ))}
            {isLoading && (
              <LoadingTableSkeleton
                row={rowsPerPage}
                column={TABLE_HEAD_COIN_LIST.length + 1}
              />
            )}
            <TableNoData isNotFound={!isLoading && !coinList?.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={lodash.isEmpty(totalItems) ? (totalItems as number) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label={'Thu gọn'}
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>
    </Paper>
  );
};
export default CoinListTable;
