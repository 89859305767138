// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  confirmModal: {
    content: 'Hành động này không thể hoàn tác',
    cancel: 'Huỷ bỏ',
    confirm: 'Xác nhận',
  },
  demo: {
    title: `Vietnamese`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `tài liệu`,
  },
  groupUser: {
    title: 'Quản lí nhóm người dùng',
    create: 'Tạo nhóm người dùng',
    edit: 'Sửa nhóm người dùng',
    detail: 'Thông tin nhóm người dùng',
    list: 'Danh sách nhóm người dùng',
    createSuccess: 'Tạo mới thành công',
    createFail: 'Tạo mới thất bại',
    editSuccess: 'Chỉnh sửa thành công',
    editFail: 'Chỉnh sửa thất bại',
    form: {
      groupName: 'Tên nhóm người dùng',
      desc: 'Mô tả',
      listUser: 'Các ID thành viên nhóm',
      status: 'Trạng thái',
      pickUser: 'Chọn người dùng',
      importFileUser: 'Nhập file người dùng',
      add: 'Thêm',
      editListCurrentUser: 'Chỉnh sủa danh sách người dùng hiện tại',
      listCurrentUser: 'Danh sách người dùng hiện tại',
    },
    deleteSuccess: 'Xóa nhóm người dùng thành công !',
    deleteFail: 'Xóa nhóm người dùng thất bại !',
  },
  app: `ứng dụng`,
  user: `người dùng`,
  list: `danh sách`,
  edit: `chỉnh sửa`,
  shop: `cửa hàng`,
  blog: `blog`,
  post: `bài viết`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách bài viết`,
  create: `tạo`,
  kanban: `bảng`,
  general: `chung`,
  banking: `ngân hàng`,
  booking: `đặt phòng`,
  profile: `hồ sơ`,
  account: `tải khoản`,
  product: `sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  menu_level_1: `menu cấp 1`,
  menu_level_2a: `menu cấp 2a`,
  menu_level_2b: `menu cấp 2b`,
  menu_level_3a: `menu cấp 3a`,
  menu_level_3b: `menu cấp 3b`,
  menu_level_4a: `menu cấp 4a`,
  menu_level_4b: `menu cấp 4b`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  item_external_link: `mục liên kết ngoài`,
  description: `mô tả`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
};

export default vn;
