export const DEFAULT_VALUE_SEARCH_USER = {
  searchText: '',
};

export const CURRENT_USER_TABLE_HEAD = [
  {
    id: 'userId',
    label: 'Mã khách hàng',
    align: 'left',
  },
  {
    id: 'name',
    label: 'Họ và tên',
    align: 'left',
  },
  {
    id: 'phoneNumber',
    label: 'Số điện thoại',
    align: 'left',
  },
  {
    id: 'tier',
    label: 'Hạng thành viên',
    align: 'center',
  },
  {
    id: 'birthday',
    label: 'Ngày sinh',
    align: 'center',
  },
  {
    id: 'status',
    label: 'Trạng thái tài khoản',
    align: 'center',
  },
];
