import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/common/redux/store';

type StateProps = {
  isOpenModalDelete: boolean;
};

const initialState: StateProps = {
  isOpenModalDelete: false,
};

export const groupUserManageSlice = createSlice({
  name: 'groupUserManage',
  initialState,
  reducers: {
    setIsOpenModalDelete: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalDelete = action.payload;
    },
  },
});

export const { setIsOpenModalDelete } = groupUserManageSlice.actions;

export const isOpenModalDeleteSelector = (state: RootState) =>
  state.groupUserManage.isOpenModalDelete;

export default groupUserManageSlice.reducer;
