// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
export const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  eventRemaining: (id: string) => path(ROOTS_AUTH, `/eventRemaining/${id}`),
  eventQ4WebviewCls: path(ROOTS_AUTH, '/event-q4-cls/:type'),
  eventQ1WebviewList: path(ROOTS_AUTH, '/event-q1-list/:type'),
  eventQ1WebviewCls: path(ROOTS_AUTH, '/event-q1-cls/:type'),
  environmentProtectionWebview: path(ROOTS_AUTH, '/environment-protection'),
  webViewExchangePhoneNumber: path(ROOTS_AUTH, '/exchange-phone-number'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: '/dashboard/stories',
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admins'),
    list: path(ROOTS_DASHBOARD, '/admins'),
    create: path(ROOTS_DASHBOARD, '/admins/create'),
    edit: (adminId: number) => path(ROOTS_DASHBOARD, `/admins/${adminId}`),
  },
  storeAdmin: {
    root: path(ROOTS_DASHBOARD, '/stories'),
    list: path(ROOTS_DASHBOARD, '/stories'),
    new: path(ROOTS_DASHBOARD, '/store/new'),
    shop_invitation: path(ROOTS_DASHBOARD, '/shop-invitation'),
    edit_store: (editStoreId: string) => path(ROOTS_DASHBOARD, `/stories/${editStoreId}`),
    edit_shop: (shopID: string) => path(ROOTS_DASHBOARD, `/shop-invitation/${shopID}`),
  },
  eventPromotionIV: {
    root: path(ROOTS_DASHBOARD, '/event-promotion-IV'),
    list: path(ROOTS_DASHBOARD, '/event-promotion-IV'),
    new: path(ROOTS_DASHBOARD, '/event-promotion-IV/new'),
    view: (id: number) => path(ROOTS_DASHBOARD, `/event-promotion-IV/${id}`),
    edit: (eventId: number) =>
      path(ROOTS_DASHBOARD, `/event-promotion-IV/edit/${eventId}`),
  },

  eventAdmin: {
    root: path(ROOTS_DASHBOARD, '/event-history'),
    historyPrize: path(ROOTS_DASHBOARD, '/event-history'),
    createPrize: (eventID: string) =>
      path(ROOTS_DASHBOARD, `/event-create-prize/${eventID}`),
    listPrize: (eventID: string) =>
      path(ROOTS_DASHBOARD, `/event-list-prize/event-${eventID}`),
    editEventPrize: path(ROOTS_DASHBOARD, '/event/event-prize-edit/:id'),
    editFileEvent: (idEvent: number) =>
      path(ROOTS_DASHBOARD, `/event/event-prize-edit/${idEvent}`),
  },

  manageEventQuarterOne: {
    root: path(ROOTS_DASHBOARD, '/event-quarter-one'),
    list: path(ROOTS_DASHBOARD, '/event-quarter-one'),
    new: path(ROOTS_DASHBOARD, '/add-new-event'),
    edit: (idEventOne: number) =>
      path(ROOTS_DASHBOARD, `/event-quarter-one/${idEventOne}`),
    view: (idEvent: number) =>
      path(ROOTS_DASHBOARD, `/event-quarter-one/view/${idEvent}`),
  },

  eventPrizeQ1: {
    root: path(ROOTS_DASHBOARD, '/event-q1-prize'),
    list: path(ROOTS_DASHBOARD, '/event-q1-prize/event/:eventId'),
    create: path(ROOTS_DASHBOARD, '/event-q1-prize/event/:eventId/create'),
    edit: path(ROOTS_DASHBOARD, '/event-q1-prize/event/:eventId/:prizeId'),
    detail: path(ROOTS_DASHBOARD, '/event-q1-prize/event/:eventId/:prizeId/detail'),
  },

  eventQ1GroupEvent: {
    root: path(ROOTS_DASHBOARD, '/event-q1-groupEvent'),
    listGroupEvent: path(ROOTS_DASHBOARD, '/event-q1-groupEvent/list'),
    addGroupEvent: path(ROOTS_DASHBOARD, '/event-q1-groupEvent/add'),
    editGroupEvent: (idEvent: number) =>
      path(ROOTS_DASHBOARD, `/event-q1-groupEvent/${idEvent}`),
    viewGroupEvent: (idEvent: number) =>
      path(ROOTS_DASHBOARD, `/event-q1-groupEvent/view/${idEvent}`),
  },

  eventHistoryPrize: {
    listHistoryPrize: path(ROOTS_DASHBOARD, '/event-history-prize/list'),
  },

  eventAddCan: {
    list: path(ROOTS_DASHBOARD, '/event-add-can'),
    create: path(ROOTS_DASHBOARD, '/event-add-can/create'),
    edit: path(ROOTS_DASHBOARD, '/event-add-can/edit/:id'),
    detail: path(ROOTS_DASHBOARD, '/event-add-can/detail/:id'),
  },
  campaign_manage: {
    root: path(ROOTS_DASHBOARD, '/campaign-manage'),
    list: path(ROOTS_DASHBOARD, '/campaign-manage/list'),
    create: path(ROOTS_DASHBOARD, '/campaign-manage/create'),
    detail: path(ROOTS_DASHBOARD, '/campaign-manage/detail/:id'),
    edit: path(ROOTS_DASHBOARD, '/campaign-manage/edit/:id'),
    listGroupUser: path(ROOTS_DASHBOARD, `/campaign-manage/list-group-user`),
    editGroupUser: (userId: number) =>
      path(ROOTS_DASHBOARD, `/campaign-manage/edit-group-user/${userId}`),
    createGroupUser: path(ROOTS_DASHBOARD, '/campaign-manage/create-group-user'),
  },
  floatingButton: {
    root: path(ROOTS_DASHBOARD, '/floating-button-manage'),
    edit: path(ROOTS_DASHBOARD, '/floating-button-manage/edit/:id'),
  },
  banner_manage: {
    root: path(ROOTS_DASHBOARD, '/banner-manage'),
    list: path(ROOTS_DASHBOARD, '/banner-manage/list'),
    create: path(ROOTS_DASHBOARD, '/banner-manage/create'),
    edit: path(ROOTS_DASHBOARD, '/banner-manage/edit/:id'),
  },
  giftAdmin: {
    root: path(ROOTS_DASHBOARD, '/gifts'),
    list: path(ROOTS_DASHBOARD, '/gifts'),
    orderList: path(ROOTS_DASHBOARD, '/gifts-order'),
    create: path(ROOTS_DASHBOARD, '/gifts/create'),
    edit: path(ROOTS_DASHBOARD, `/gifts/:id`),
    categoryList: path(ROOTS_DASHBOARD, `/gift-categories`),
    categoryCreate: path(ROOTS_DASHBOARD, `/gift-categories/create`),
    categoryEdit: path(ROOTS_DASHBOARD, `/gift-categories/:id`),
    preOrderList: path(ROOTS_DASHBOARD, `/gift-pre-orders`),
  },
  orderAdmin: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    edit: path(ROOTS_DASHBOARD, `/orders/:osOrderId/:orderId`),
    editShowShip: path(ROOTS_DASHBOARD, `/orders/ship/:osOrderId/:isShow`),
  },

  coinAdmin: {
    root: path(ROOTS_DASHBOARD, '/coins'),
    list: path(ROOTS_DASHBOARD, '/coins'),
  },

  filterAdmin: {
    root: path(ROOTS_DASHBOARD, `/filters`),
    filterCreate: path(ROOTS_DASHBOARD, `/filters/create`),
    filterDetail: path(ROOTS_DASHBOARD, `/filter/:id`),
    filterEdit: path(ROOTS_DASHBOARD, `/filter-edit/:id`),
  },
  hiddenTagsAdmin: {
    root: path(ROOTS_DASHBOARD, `/hiddenTags`),
    hiddenTagsCreate: path(ROOTS_DASHBOARD, `/hiddenTags/create`),
    hiddenTagsEdit: path(ROOTS_DASHBOARD, `/hiddenTags/:id`),
  },
};
