import { useState } from 'react';
import { Checkbox, Switch, TableCell, TableRow, Box, Modal, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Can from 'src/common/lib/Can';
import { replacePathParams } from 'src/common/utils/replaceParams';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { FORMAT_DATE_FILTER } from 'src/common/constants/common.constants';
import Image from 'src/common/components/Image';
import { IPropsCoinTableRow } from 'src/coin/common/interface';
import { CoinStatusList } from 'src/coin/common/constant';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';

const CoinListRow = ({ row, selected, onSelectRow, onDeleteRow }: IPropsCoinTableRow) => {
  const navigate = useNavigate();
  const {
    id,
    orderCode,
    reason,
    returnDate,
    status,
    statusDescription,
    totalGifts,
    totalPoint,
    transactionCode,
    userGiftSnapshot,
    userId,
    userInfo,
    deliveryCode,
    giftCode,
  } = row;


  const statusElement = CoinStatusList.find((item) => item.value === status);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isShowingFee, setIsShowingFee] = useState<boolean>(true);

  const handleEditRow = () => {
    navigate(
      replacePathParams(PATH_DASHBOARD.orderAdmin.edit, {
        osOrderId: id,
        orderId: orderCode,
      })
    );
  };

  const handleOnChangeStatus = async (active: boolean) => {
    setIsShowingFee(active);
  };

  return (
    <TableRow hover selected={selected}>
      <Can do="update" on="all">
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onChange={(e) => onSelectRow(e.target.checked)} />
        </TableCell>
      </Can>
      <TableCell align="left" sx={{ minWidth: 90 }}>
        {id}
      </TableCell>

      <TableCell align="left" sx={{ minWidth: 120 }}>
        {deliveryCode}
      </TableCell>

      <TableCell align="left">{transactionCode}</TableCell>

      <TableCell align="left">{orderCode}</TableCell>

      <TableCell align="left" sx={{ minWidth: 120 }}>
        {userGiftSnapshot.gift.id}
      </TableCell>

      <TableCell align="left" sx={{ minWidth: 300 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          {userGiftSnapshot?.gift?.images && (
            <>
              <Image
                src={userGiftSnapshot?.gift?.images[0]}
                alt="image"
                sx={{ objectFit: 'cover', width: 64, height: 64, borderRadius: 1 }}
              />
            </>
          )}
          <span>{userGiftSnapshot?.gift?.name}</span>
        </Box>
      </TableCell>

      <TableCell align="left" sx={{ minWidth: 120 }}>
        {userId}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: 120 }}>
        <div>{userInfo.recipientPhone}</div>
        <div>{userInfo.recipientName}</div>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: 120 }}>
        {totalGifts}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: 120 }}>
        {totalPoint}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: 160 }}>
        <Box
          sx={{
            color: statusElement?.style?.color,
            background: statusElement?.style?.backgroundColor,
            padding: '1px 8px',
            borderRadius: '6px',
            maxWidth: 'fit-content',
          }}
        >
          {statusDescription}
        </Box>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: 150 }}>
        {dayjs(returnDate).isValid() ? dayjs(returnDate).format(FORMAT_DATE_FILTER) : ''}
      </TableCell>
      <TableCell align="left" sx={{ minWidth: 120 }}>
        {reason}
      </TableCell>

      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <span style={{ textAlign: 'center' }}>
            Bạn chắc chắn muốn {isShowingFee ? <>ẩn</> : <>hiện</>} phí ship?
          </span>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '12px',
              width: '100%',
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setIsOpenModal(false)}
              sx={{ flex: 1, width: '100%' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => {
                setIsOpenModal(false);
                setIsShowingFee(!isShowingFee);
              }}
              sx={{ flex: 1, width: '100%' }}
            >
              Yes
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </TableRow>
  );
};

export default CoinListRow;
