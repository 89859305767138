import React, { useEffect, useState } from 'react';
import {
  Paper,
  Stack,
  Switch,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Button,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/common/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useShowSnackbar from 'src/common/hooks/useMessage';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { DEFAULT_VALUE_FILTER_FORM } from 'src/filter/common/constant';
import { DeleteOutline, AddOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectFilterDetail } from 'src/filter/common/slice';
import { filterFormSchema } from 'src/filter/common/schema';
import { useCreateFilterDetail } from 'src/filter/common/hooks/useCreateFilterDetail';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

function convertText(input: string): string {
  const normalizedText = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const upperCaseText = normalizedText.toUpperCase();
  const finalText = upperCaseText.replace(/[^A-Z0-9]/g, '_');
  return finalText;
}
export interface IFilterForm {
  name: string;
  priority: string;
  isActive: boolean;
  code: string;
}

const DEFAULT_ITEMS = {
  name: '',
  code: '',
  firstLevel: [],
};

const LIMIT_FILTER = 5;
export default function FilterEditForm() {
  const navigate = useNavigate();
  const [items, setItems] = useState<any[]>([DEFAULT_ITEMS]);
  const [editItemIndex, setEditItemIndex] = useState<any | null>(null);
  const [editfirstLevelIndex, setEditfirstLevelIndex] = useState<any | null>(null);
  const [editThirdLevelIndex, setEditThirdLevelIndex] = useState<any | null>(null);

  const filterDetail = useSelector(selectFilterDetail);
  const methods = useForm<IFilterForm>({
    resolver: yupResolver(filterFormSchema),
    defaultValues: DEFAULT_VALUE_FILTER_FORM,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = methods;
  const { showSuccessSnackbar, showErrorSnackbar } = useShowSnackbar();
  const { id } = useParams();

  const handleAddFilterMain = () => {
    if (items.length < LIMIT_FILTER) {
      setItems([...items, DEFAULT_ITEMS]);
    } else {
      showErrorSnackbar(`Giới hạn thêm giá trị bộ lọc chính là: ${LIMIT_FILTER}`);
    }
  };

  const handleDeleteFilterMain = (index: any) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleAddfilterSub = (itemIndex: any) => {
    // eslint-disable-next-line no-constant-condition
    if (items[itemIndex]?.firstLevel?.length < LIMIT_FILTER) {
      const newItems = [...items];
      newItems[itemIndex] = {
        ...newItems[itemIndex],
        firstLevel: [
          ...newItems[itemIndex].firstLevel,
          {
            name: '',
            isActive: true,
            value: [],
          },
        ],
      };
      setItems(newItems);
    } else {
      showErrorSnackbar(`Giới hạn thêm bộ lọc phụ là: ${LIMIT_FILTER}`);
    }
  };

  const handleAddFilterSubvalue = (itemIndex: number, firstLevelIndex: number) => {
    const newItems = [...items]; // Shallow copy of the items array

    // Create a new object for the firstLevel array
    const updatedFirstLevel = newItems[itemIndex].firstLevel.map(
      (firstLevelItem: any, index: any) => {
        if (index !== firstLevelIndex) return firstLevelItem;

        // Create a new value array with the added item
        const updatedValue = [
          ...firstLevelItem.value,
          {
            name: '',
            isActive: true,
            secondLevel: [],
          },
        ];

        // Return a new firstLevelItem with updated value
        return {
          ...firstLevelItem,
          value: updatedValue,
        };
      }
    );

    // Create a new item with the updated firstLevel array
    const updatedItems = newItems.map((item, index) => {
      if (index !== itemIndex) return item;

      return {
        ...item,
        firstLevel: updatedFirstLevel,
      };
    });

    setItems(updatedItems); // Set the new items state
  };

  const handleDeleteFilterSubvalue = (
    itemIndex: any,
    firstLevelIndex: any,
    secondLevelIndex: any
  ) => {
    setItems((prevItems) => {
      // Create a deep copy of the previous items state
      const newItems = prevItems.map((item) => ({
        ...item,
        firstLevel: item.firstLevel.map((fl: any, idx: any) =>
          idx === firstLevelIndex
            ? {
                ...fl,
                value: fl.value.filter((_: any, i: any) => i !== secondLevelIndex),
              }
            : fl
        ),
      }));

      return newItems;
    });
  };

  const handleAddNameSmallFilter = (
    itemIndex: number,
    firstLevelIndex: number,
    secondLevelIndex: number
  ) => {
    if (
      items[itemIndex]?.firstLevel[firstLevelIndex]?.value[secondLevelIndex].secondLevel
        .length < LIMIT_FILTER
    ) {
      setItems((prevItems) => {
        const newItems = JSON.parse(JSON.stringify(prevItems));
        const targetItem =
          newItems[itemIndex]?.firstLevel[firstLevelIndex]?.value[secondLevelIndex];

        if (targetItem) {
          if (!Array.isArray(targetItem.secondLevel)) {
            targetItem.secondLevel = [];
          }

          targetItem.secondLevel = [
            ...targetItem.secondLevel,
            {
              name: '',
              isActive: true,
              code: '',
              value: [],
            },
          ];
        }

        return newItems;
      });
    } else {
      showErrorSnackbar(`Giới hạn thêm tên bộ lọc nhỏ là: ${LIMIT_FILTER}`);
    }
  };

  const handleAddValueSmallFilter = (
    itemIndex: any,
    firstLevelIndex?: any,
    secondLevelIndex?: any,
    thirdLevelIndex?: any
  ) => {
    // eslint-disable-next-line no-constant-condition
    if (true) {
      setItems((prevItems) => {
        const newItems = JSON.parse(JSON.stringify(prevItems));
        const targetItem =
          newItems[itemIndex]?.firstLevel[firstLevelIndex]?.value[secondLevelIndex]
            .secondLevel[thirdLevelIndex];

        if (targetItem) {
          if (!Array.isArray(targetItem.value)) {
            targetItem.value = [];
          }

          targetItem.value = [
            ...targetItem.value,
            {
              name: '',
              isActive: true,
            },
          ];
        }

        return newItems;
      });
    }
  };

  const handleDeleteValueSmallFilter = (
    itemIndex: any,
    firstLevelIndex?: any,
    secondLevelIndex?: any,
    thirdLevelIndex?: any,
    fourthLevelIndex?: any
  ) => {
    // Make a copy of items with map
    const newItems = items.map((item: any, i: number) => {
      if (i === itemIndex) {
        return {
          ...item,
          firstLevel: item.firstLevel
            ? item.firstLevel.map((firstLevel: any, j: number) => {
                if (j === firstLevelIndex) {
                  return {
                    ...firstLevel,
                    value: firstLevel.value
                      ? firstLevel.value.map((secondLevel: any, k: number) => {
                          if (k === secondLevelIndex) {
                            return {
                              ...secondLevel,
                              secondLevel: secondLevel.secondLevel
                                ? secondLevel.secondLevel.map(
                                    (thirdLevel: any, l: number) => {
                                      if (l === thirdLevelIndex) {
                                        return {
                                          ...thirdLevel,
                                          value: thirdLevel.value
                                            ? thirdLevel.value.filter(
                                                (_: any, m: number) =>
                                                  m !== fourthLevelIndex
                                              )
                                            : [],
                                        };
                                      }
                                      return thirdLevel;
                                    }
                                  )
                                : [],
                            };
                          }
                          return secondLevel;
                        })
                      : [],
                  };
                }
                return firstLevel;
              })
            : [],
        };
      }
      return item;
    });

    // Update the state with the new items array
    setItems(newItems);
  };

  const handleToggleActive = (
    itemIndex: any,
    firstLevelIndex?: any,
    secondLevelIndex?: any,
    thirdLevelIndex?: any
  ) => {
    const newItems = [...items];
    if (thirdLevelIndex !== undefined) {
      const newItem = items.map((item, i) => {
        if (i !== itemIndex) return item;
        return {
          ...item,
          firstLevel: item.firstLevel.map((firstLevelItem: any, j: any) => {
            if (j !== firstLevelIndex) return firstLevelItem;
            return {
              ...firstLevelItem,
              value: firstLevelItem.value.map((secondLevelItem: any, k: any) => {
                if (k !== secondLevelIndex) return secondLevelItem;
                return {
                  ...secondLevelItem,
                  secondLevel: secondLevelItem.secondLevel.map(
                    (thirdLevelItem: any, l: any) => {
                      if (l !== thirdLevelIndex) return thirdLevelItem;
                      // console.log(thirdLevelItem.isActive);

                      return {
                        ...thirdLevelItem,
                        isActive: !convertToBoolean(thirdLevelItem.isActive),
                      };
                    }
                  ),
                };
              }),
            };
          }),
        };
      });
      setItems(newItem);
      return;
    } else if (secondLevelIndex !== undefined) {
      const newItem = items.map((item, i) => {
        if (i !== itemIndex) return item;
        return {
          ...item,
          firstLevel: item.firstLevel.map((firstLevelItem: any, j: any) => {
            // console.log(firstLevelItem);

            if (j !== firstLevelIndex) return firstLevelItem;
            return {
              ...firstLevelItem,
              value: firstLevelItem.value.map((secondLevelItem: any, k: any) => {
                // console.log(secondLevelItem);

                if (k !== secondLevelIndex) return secondLevelItem;
                return {
                  ...secondLevelItem,
                  secondLevel: secondLevelItem.secondLevel.map(
                    (thirdLevelItem: any, l: any) => {
                      if (l !== thirdLevelIndex) return thirdLevelItem;

                      return {
                        ...thirdLevelItem,
                        isActive: !convertToBoolean(thirdLevelItem.isActive),
                      };
                    }
                  ),
                };
              }),
            };
          }),
        };
      });

      setItems(newItem);
    } else if (firstLevelIndex !== undefined) {
      const newItem = items.map((item, i) => {
        if (i !== itemIndex) return item;
        return {
          ...item,
          firstLevel: item.firstLevel.map((firstLevelItem: any, j: any) => {
            if (j !== firstLevelIndex) return firstLevelItem;
            return {
              ...firstLevelItem,
              isActive: !convertToBoolean(firstLevelItem.isActive),
            };
          }),
        };
      });
      setItems(newItem);
      return;
    } else {
      newItems[itemIndex].isActive = !newItems[itemIndex].isActive;
    }

    setItems(newItems);
  };

  const { mutate: postFilter } = useCreateFilterDetail({
    onSuccess: () => {
      if (id) {
        showSuccessSnackbar('Cập nhật bộ lọc thành công');
      } else {
        showSuccessSnackbar('Tạo bộ lọc thành công');
      }
      navigate(PATH_DASHBOARD.filterAdmin.root);
    },

    onError: () => {
      if (id) {
        showErrorSnackbar('Cập nhật bộ lọc thất bại');
      } else {
        showErrorSnackbar('Tạo bộ lọc thất bại');
      }
    },
  });

  function removeEmptyNameObjects(data: any | any[]): any | any[] {
    if (Array.isArray(data)) {
      return data.filter((item) => item.name !== '').map(removeEmptyNameObjects);
    }

    if (typeof data === 'object' && data !== null) {
      const newObj: any = {};
      for (const [key, value] of Object.entries(data)) {
        if (key === 'name' && value === '') {
          continue;
        }
        if (Array.isArray(value)) {
          newObj[key] = removeEmptyNameObjects(value) as any[];
        } else if (typeof value === 'object' && value !== null) {
          newObj[key] = removeEmptyNameObjects(value) as any;
        } else {
          newObj[key] = value;
        }
      }
      return newObj;
    }

    return data;
  }

  const onSubmit = async (data: any) => {
    // console.log(items[0].firstLevel[0].value[0].secondLevel);
    // console.log(removeEmptyNameObjects(items));

    const payload = {
      name: data.name,
      code: id ? id : convertText(data.name),
      isActive: data.isActive,
      priority: parseInt(data.priority, 10),
      value: removeEmptyNameObjects(items),
    };

    postFilter(payload);
  };

  function convertToBoolean(value: string | boolean | undefined): boolean {
    // Handle the case when value is undefined
    if (value === undefined) return false;

    // Handle boolean values directly
    if (typeof value === 'boolean') return value;

    // Handle string values
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }

    // Default case: return false for any other types
    return false;
  }

  useEffect(() => {
    if (filterDetail && filterDetail?.name !== '') {
      const { priority, isActive, name, code, value } = filterDetail;
      setItems([...(value || [])]);
      reset({ name, priority, isActive, code });
    }
  }, [filterDetail, reset]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 2 }}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={3} paddingTop="20px">
            <Stack width="70%">
              <RHFTextField
                name="name"
                label="Tên bộ lọc chính *"
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    py: 0.75,
                    fontSize: '14px',
                  },
                }}
              />
            </Stack>
            <Stack width="20%">
              <RHFTextField
                name="priority"
                label="Priority *"
                type="any"
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    py: 0.75,
                    fontSize: '14px',
                  },
                }}
              />
            </Stack>
            <Stack width="10%" direction={'row'} alignItems={'center'} spacing={1}>
              <Typography fontSize="14px" style={{ fontWeight: 'bold' }}>
                Active
              </Typography>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={convertToBoolean(field.value)}
                    size="small"
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={3} paddingTop="20px">
            <Button onClick={handleAddFilterMain} variant="contained" color="primary">
              Thêm giá trị bộ lọc chính
              <IconButton edge="end" aria-label="add" style={{ color: '#fff' }}>
                <AddOutlined />
              </IconButton>
            </Button>
          </Stack>
        </Stack>

        <div style={{ padding: '20px' }}>
          <List>
            {items.map((item, itemIndex) => (
              <Paper
                key={itemIndex}
                style={{ marginBottom: 10 }}
                elevation={6}
                sx={{ p: 4, borderRadius: 2 }}
              >
                <ListItem>
                  <RHFTextField
                    value={item.name}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[itemIndex] = {
                        ...newItems[itemIndex],
                        name: e.target.value,
                        code: convertText(e.target.value),
                      };
                      setItems(newItems);
                    }}
                    name={`mainFilter${itemIndex}`}
                    label="Giá trị bộ lọc chính"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-root': {
                        py: 0.75,
                        fontSize: '14px',
                      },
                    }}
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteFilterMain(itemIndex)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={editItemIndex === itemIndex} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem style={{ paddingLeft: '20px' }}>
                      <Stack spacing={2} direction="row">
                        <Button
                          onClick={() => handleAddfilterSub(itemIndex)}
                          variant="contained"
                          color="primary"
                        >
                          Thêm tên bộ lọc phụ
                          <IconButton
                            edge="end"
                            aria-label="add"
                            style={{ color: '#fff' }}
                          >
                            <AddOutlined />
                          </IconButton>
                        </Button>
                      </Stack>
                    </ListItem>
                    {item.firstLevel.map(
                      (
                        firstLevel: {
                          name: unknown;
                          isActive: boolean | undefined;
                          value: any[];
                        },
                        firstLevelIndex:
                          | string
                          | any
                          | ((prevState: any | null) => any | null)
                          | null
                          | undefined
                      ) => (
                        <Paper
                          key={firstLevelIndex}
                          style={{ marginBottom: 10, marginLeft: 20 }}
                          elevation={3}
                          sx={{ p: 2, borderRadius: 2 }}
                        >
                          <ListItem>
                            <RHFTextField
                              value={firstLevel.name}
                              onChange={(e) => {
                                const newItems = items.map((item, i) => {
                                  if (i !== itemIndex) return item;
                                  return {
                                    ...item,
                                    firstLevel: item.firstLevel.map(
                                      (firstLevelItem: any, j: any) => {
                                        if (j !== firstLevelIndex) return firstLevelItem;
                                        return {
                                          ...firstLevelItem,
                                          name: e.target.value,
                                          code: convertText(e.target.value),
                                        };
                                      }
                                    ),
                                  };
                                });

                                setItems(newItems);
                              }}
                              name={`firstLevelFilter${itemIndex}-${firstLevelIndex}`}
                              label="Tên bộ lọc phụ"
                              InputLabelProps={{
                                shrink: true,
                                sx: {
                                  paddingLeft: '40px',
                                },
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  py: 0.75,
                                  fontSize: '14px',
                                },
                                paddingLeft: '35px',
                              }}
                            />
                            <Typography
                              fontSize="14px"
                              style={{ fontWeight: 'bold', marginLeft: 10 }}
                            >
                              Active
                            </Typography>

                            <Switch
                              checked={convertToBoolean(firstLevel.isActive)}
                              onChange={() =>
                                handleToggleActive(itemIndex, firstLevelIndex)
                              }
                              size="small"
                            />
                          </ListItem>
                          <Collapse
                            in={editfirstLevelIndex === firstLevelIndex}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem style={{ paddingLeft: '50px' }}>
                                <Stack spacing={2} direction="row">
                                  <Button
                                    onClick={() =>
                                      handleAddFilterSubvalue(itemIndex, firstLevelIndex)
                                    }
                                    variant="contained"
                                    color="primary"
                                  >
                                    Thêm giá trị bộ lọc phụ
                                    <IconButton
                                      edge="end"
                                      aria-label="add"
                                      style={{ color: '#fff' }}
                                    >
                                      <AddOutlined />
                                    </IconButton>
                                  </Button>
                                </Stack>
                              </ListItem>
                              {firstLevel.value &&
                                firstLevel.value.map(
                                  (
                                    secondLevel: {
                                      name: unknown;
                                      isActive: boolean | undefined;
                                      secondLevel: {
                                        name: unknown;
                                        isActive: boolean | undefined;
                                        value: any[];
                                      }[];
                                    },
                                    secondLevelIndex: React.Key | number | 0
                                  ) => (
                                    <Paper
                                      key={secondLevelIndex}
                                      style={{ marginBottom: 10, marginLeft: 20 }}
                                      elevation={2}
                                      sx={{ p: 2, borderRadius: 2 }}
                                    >
                                      <ListItem>
                                        <RHFTextField
                                          value={secondLevel.name}
                                          onChange={(e) => {
                                            const newItems = items.map((item, i) => {
                                              if (i !== itemIndex) return item;

                                              return {
                                                ...item,
                                                firstLevel: item.firstLevel.map(
                                                  (firstLevelItem: any, j: any) => {
                                                    if (j !== firstLevelIndex)
                                                      return firstLevelItem;

                                                    return {
                                                      ...firstLevelItem,
                                                      value: firstLevelItem.value.map(
                                                        (valueItem: any, k: any) => {
                                                          if (k !== secondLevelIndex)
                                                            return valueItem;

                                                          return {
                                                            ...valueItem,
                                                            name: e.target.value,
                                                            code: convertText(
                                                              e.target.value
                                                            ),
                                                          };
                                                        }
                                                      ),
                                                    };
                                                  }
                                                ),
                                              };
                                            });
                                            setItems(newItems);
                                          }}
                                          name={`secondLevelFilter${itemIndex}-${firstLevelIndex}-${secondLevelIndex}`}
                                          label="Giá trị bộ lọc phụ"
                                          InputLabelProps={{ shrink: true }}
                                          sx={{
                                            '& .MuiInputBase-root': {
                                              py: 0.75,
                                              fontSize: '14px',
                                            },
                                          }}
                                        />

                                        <ListItemSecondaryAction>
                                          <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() =>
                                              handleDeleteFilterSubvalue(
                                                itemIndex,
                                                firstLevelIndex,
                                                secondLevelIndex
                                              )
                                            }
                                          >
                                            <DeleteOutline />
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                      <List component="div" disablePadding>
                                        <ListItem style={{ paddingLeft: '18px' }}>
                                          <Stack spacing={2} direction="row">
                                            <Button
                                              onClick={() =>
                                                handleAddNameSmallFilter(
                                                  itemIndex,
                                                  firstLevelIndex,
                                                  Number(secondLevelIndex)
                                                )
                                              }
                                              variant="contained"
                                              color="primary"
                                            >
                                              Thêm tên bộ lọc nhỏ
                                              <IconButton
                                                edge="end"
                                                aria-label="add"
                                                style={{ color: '#fff' }}
                                              >
                                                <AddOutlined />
                                              </IconButton>
                                            </Button>
                                          </Stack>
                                        </ListItem>
                                        {secondLevel?.secondLevel &&
                                          secondLevel.secondLevel.map(
                                            (
                                              thirdLevel: {
                                                name: unknown;
                                                isActive: boolean | undefined;
                                                value: any[];
                                              },
                                              thirdLevelIndex:
                                                | string
                                                | any
                                                | ((prevState: any | null) => any | null)
                                                | null
                                                | undefined
                                            ) => (
                                              <Paper
                                                key={thirdLevelIndex?.toString()}
                                                style={{
                                                  marginBottom: 10,
                                                  marginLeft: 20,
                                                }}
                                                elevation={1}
                                                sx={{ p: 2, borderRadius: 2 }}
                                              >
                                                <ListItem>
                                                  <RHFTextField
                                                    value={thirdLevel.name}
                                                    onChange={(e) => {
                                                      const newItems = items.map(
                                                        (item, i) => {
                                                          if (i !== itemIndex)
                                                            return item;

                                                          return {
                                                            ...item,
                                                            firstLevel:
                                                              item.firstLevel.map(
                                                                (
                                                                  firstLevelItem: any,
                                                                  j: any
                                                                ) => {
                                                                  if (
                                                                    j !== firstLevelIndex
                                                                  )
                                                                    return firstLevelItem;

                                                                  return {
                                                                    ...firstLevelItem,
                                                                    value:
                                                                      firstLevelItem.value.map(
                                                                        (
                                                                          secondLevelItem: any,
                                                                          k: any
                                                                        ) => {
                                                                          if (
                                                                            k !==
                                                                            secondLevelIndex
                                                                          )
                                                                            return secondLevelItem;

                                                                          return {
                                                                            ...secondLevelItem,
                                                                            secondLevel:
                                                                              secondLevelItem.secondLevel.map(
                                                                                (
                                                                                  thirdLevelItem: any,
                                                                                  l: any
                                                                                ) => {
                                                                                  if (
                                                                                    l !==
                                                                                    thirdLevelIndex
                                                                                  )
                                                                                    return thirdLevelItem;

                                                                                  return {
                                                                                    ...thirdLevelItem,
                                                                                    name: e
                                                                                      .target
                                                                                      .value,
                                                                                    code: convertText(
                                                                                      e
                                                                                        .target
                                                                                        .value
                                                                                    ),
                                                                                  };
                                                                                }
                                                                              ),
                                                                          };
                                                                        }
                                                                      ),
                                                                  };
                                                                }
                                                              ),
                                                          };
                                                        }
                                                      );
                                                      setItems(newItems);
                                                    }}
                                                    name={`thirdLevelFilter${itemIndex}-${firstLevelIndex}-${secondLevelIndex}-${thirdLevelIndex}`}
                                                    label="Tên bộ lọc nhỏ"
                                                    InputLabelProps={{ shrink: true }}
                                                    sx={{
                                                      '& .MuiInputBase-root': {
                                                        py: 0.75,
                                                        fontSize: '14px',
                                                      },
                                                    }}
                                                  />
                                                  <Typography
                                                    fontSize="14px"
                                                    style={{
                                                      fontWeight: 'bold',
                                                      marginLeft: 10,
                                                    }}
                                                  >
                                                    Active
                                                  </Typography>
                                                  <Switch
                                                    checked={convertToBoolean(
                                                      thirdLevel.isActive
                                                    )}
                                                    onChange={() =>
                                                      handleToggleActive(
                                                        itemIndex,
                                                        firstLevelIndex,
                                                        secondLevelIndex,
                                                        thirdLevelIndex
                                                      )
                                                    }
                                                    size="small"
                                                  />
                                                </ListItem>
                                                <Collapse
                                                  in={
                                                    editThirdLevelIndex ===
                                                    thirdLevelIndex
                                                  }
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <List component="div" disablePadding>
                                                    <ListItem
                                                      style={{ paddingLeft: '18px' }}
                                                    >
                                                      <Stack spacing={2} direction="row">
                                                        <Button
                                                          onClick={() =>
                                                            handleAddValueSmallFilter(
                                                              itemIndex,
                                                              firstLevelIndex,
                                                              secondLevelIndex,
                                                              thirdLevelIndex
                                                            )
                                                          }
                                                          variant="contained"
                                                          color="primary"
                                                        >
                                                          Thêm giá trị bộ lọc nhỏ
                                                          <IconButton
                                                            edge="end"
                                                            aria-label="add"
                                                            style={{ color: '#fff' }}
                                                          >
                                                            <AddOutlined />
                                                          </IconButton>
                                                        </Button>
                                                      </Stack>
                                                    </ListItem>
                                                    {thirdLevel.value.map(
                                                      (fourthLevel, fourthLevelIndex) => (
                                                        <ListItem
                                                          key={fourthLevelIndex}
                                                          style={{ paddingLeft: '20px' }}
                                                        >
                                                          <RHFTextField
                                                            value={fourthLevel.name}
                                                            onChange={(e) => {
                                                              const newItems = items.map(
                                                                (item, i) => {
                                                                  if (i !== itemIndex)
                                                                    return item;

                                                                  return {
                                                                    ...item,
                                                                    firstLevel:
                                                                      item.firstLevel.map(
                                                                        (
                                                                          firstLevelItem: any,
                                                                          j: any
                                                                        ) => {
                                                                          if (
                                                                            j !==
                                                                            firstLevelIndex
                                                                          )
                                                                            return firstLevelItem;

                                                                          return {
                                                                            ...firstLevelItem,
                                                                            value:
                                                                              firstLevelItem.value.map(
                                                                                (
                                                                                  secondLevelItem: any,
                                                                                  k: any
                                                                                ) => {
                                                                                  if (
                                                                                    k !==
                                                                                    secondLevelIndex
                                                                                  )
                                                                                    return secondLevelItem;

                                                                                  return {
                                                                                    ...secondLevelItem,
                                                                                    secondLevel:
                                                                                      secondLevelItem.secondLevel.map(
                                                                                        (
                                                                                          thirdLevelItem: any,
                                                                                          l: any
                                                                                        ) => {
                                                                                          if (
                                                                                            l !==
                                                                                            thirdLevelIndex
                                                                                          )
                                                                                            return thirdLevelItem;

                                                                                          return {
                                                                                            ...thirdLevelItem,
                                                                                            value:
                                                                                              thirdLevelItem.value.map(
                                                                                                (
                                                                                                  fourthLevelItem: any,
                                                                                                  m: any
                                                                                                ) => {
                                                                                                  if (
                                                                                                    m !==
                                                                                                    fourthLevelIndex
                                                                                                  )
                                                                                                    return fourthLevelItem;

                                                                                                  return {
                                                                                                    ...fourthLevelItem,
                                                                                                    name: e
                                                                                                      .target
                                                                                                      .value,
                                                                                                    code: convertText(
                                                                                                      e
                                                                                                        .target
                                                                                                        .value
                                                                                                    ),
                                                                                                  };
                                                                                                }
                                                                                              ),
                                                                                          };
                                                                                        }
                                                                                      ),
                                                                                  };
                                                                                }
                                                                              ),
                                                                          };
                                                                        }
                                                                      ),
                                                                  };
                                                                }
                                                              );
                                                              setItems(newItems);
                                                            }}
                                                            name={`fourthLevelFilter${itemIndex}-${firstLevelIndex}-${secondLevelIndex}-${thirdLevelIndex}-${fourthLevelIndex}`}
                                                            label="Giá trị bộ lọc nhỏ"
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                            sx={{
                                                              '& .MuiInputBase-root': {
                                                                py: 0.75,
                                                                fontSize: '14px',
                                                              },
                                                            }}
                                                          />

                                                          <ListItemSecondaryAction>
                                                            <IconButton
                                                              edge="end"
                                                              aria-label="delete"
                                                              onClick={() =>
                                                                handleDeleteValueSmallFilter(
                                                                  itemIndex,
                                                                  firstLevelIndex,
                                                                  secondLevelIndex,
                                                                  thirdLevelIndex,
                                                                  fourthLevelIndex
                                                                )
                                                              }
                                                            >
                                                              <DeleteOutline />
                                                            </IconButton>
                                                          </ListItemSecondaryAction>
                                                        </ListItem>
                                                      )
                                                    )}
                                                  </List>
                                                </Collapse>
                                                <Button
                                                  style={{ marginTop: 10 }}
                                                  onClick={() =>
                                                    setEditThirdLevelIndex(
                                                      editThirdLevelIndex ===
                                                        thirdLevelIndex
                                                        ? null
                                                        : thirdLevelIndex
                                                    )
                                                  }
                                                  variant="outlined"
                                                >
                                                  {editThirdLevelIndex === thirdLevelIndex
                                                    ? 'Đóng bộ lọc nhỏ'
                                                    : 'Hiển thị bộ lọc nhỏ'}
                                                </Button>
                                              </Paper>
                                            )
                                          )}
                                      </List>
                                    </Paper>
                                  )
                                )}
                            </List>
                          </Collapse>
                          <Button
                            style={{ marginTop: 10 }}
                            onClick={() =>
                              setEditfirstLevelIndex(
                                editfirstLevelIndex === firstLevelIndex
                                  ? null
                                  : firstLevelIndex
                              )
                            }
                            variant="outlined"
                          >
                            {editfirstLevelIndex === firstLevelIndex
                              ? 'Đóng bộ lọc phụ'
                              : 'Hiển thị bộ lọc phụ'}
                          </Button>
                        </Paper>
                      )
                    )}
                  </List>
                </Collapse>
                <Divider />
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() =>
                    setEditItemIndex(editItemIndex === itemIndex ? null : itemIndex)
                  }
                  variant="outlined"
                >
                  {editItemIndex === itemIndex
                    ? 'Đóng bộ lọc chính'
                    : 'Hiển thị bộ lọc chính'}
                </Button>
              </Paper>
            ))}
          </List>
        </div>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignSelf="flex-end"
            spacing={2} // add some spacing between the buttons if needed
            mt={1}
          >
            <LoadingButton
              type="button"
              variant="outlined"
              size="small"
              loading={isSubmitting}
              onClick={() => navigate(PATH_DASHBOARD.filterAdmin.root)}
            >
              {'Hủy'}
            </LoadingButton>

            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              loading={isSubmitting}
            >
              {id ? 'Lưu' : 'Tạo mới'}
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </FormProvider>
  );
}
