export const CoinStatusList = [
  {
    label: 'DONE',
    value: 'DONE',
    style: { color: '#B78103', backgroundColor: '#FFC10729' },
  },
  { label: 'CANCEL', value: 'CANCEL' },
  { label: 'PENDING', value: 'PENDING' },
  { label: 'PROCESSING', value: 'PROCESSING' },
  { label: 'REJECT', value: 'REJECT' },
  { label: 'REDELIVERY', value: 'REDELIVERY' },
  { label: 'SEPARATED', value: 'SEPARATED' },
  { label: 'MANUAL', value: 'MANUAL' },
];

export const TABLE_HEAD_COIN_LIST = [
  { id: 'orderId', label: 'ID', align: 'left' },
  { id: 'mvd', label: 'MVD', align: 'left' },
  { id: 'transactionId', label: 'Transaction ID', align: 'left' },
  { id: 'tdId', label: 'Order id', align: 'left' },
  { id: 'giftCode', label: 'Gift code', align: 'left' },
  { id: 'giftName', label: 'Tên quà', align: 'center' },
  { id: 'userId', label: 'User ID', align: 'left' },
  { id: 'customer', label: 'Khách hàng', align: 'left' },
  { id: 'giftCount', label: 'SL Quà', align: 'left' },
  { id: 'refundCoin', label: 'Số xu hoàn', align: 'left' },
  { id: 'status', label: 'Trạng thái', align: 'left' },
  { id: 'refundDate', label: 'Ngày hoàn xu', align: 'left' },
  { id: 'refundReason', label: 'Lý do hoàn', align: 'left' },
];

export const TABLE_HEAD_COIN_EDIT = [
  { id: 'transactionId', label: 'Transaction ID', align: 'left' },
  {
    id: 'giftName',
    label: 'Tên quà',
    align: 'left',
  },
  {
    id: 'giftCode',
    label: 'Gift Code',
    align: 'left',
  },
  {
    id: 'COINQuantity',
    label: 'Số xu',
    align: 'left',
  },
  {
    id: 'giftQuantity',
    label: 'Số lượng quà',
    align: 'left',
  },
];

export const SUCCESS_UPLOAD_CODE = 200;
