import { useQuery } from 'react-query';
import { IParamsHiddenTagsList } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getHiddenTagsV3 } from '../service';

export const useGetHiddenTags = (params: IParamsHiddenTagsList) => {
  const dataQuery = useQuery(
    [QUERY_KEYS.HIDDEN_TAG_LIST, params],
    () => getHiddenTagsV3(params),
    {
      cacheTime: 1000,
    }
  );
  return { ...dataQuery };
};
