import { useQuery } from 'react-query';
import { IParamsOrderList, IParamsOrderEditList } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getOrderEditList, getOrderList } from '../service';

export const useGetOrderList = (params: IParamsOrderList) => {
  return { ...useQuery([QUERY_KEYS.ORDER_LIST, params], () => getOrderList(params)) };
};

export const useGetOrderEditList = (params: IParamsOrderEditList) => {
  const dataQuery = useQuery([QUERY_KEYS.ORDER_EDIT_LIST, params], () =>
    getOrderEditList(params)
  );
  return { ...dataQuery };
};
