import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ENUM_TYPE_SENT,
  ICampaign,
  ICampaignListFilter,
  ICondition,
  INotifyPush,
  INotifySchedule,
} from './interface';
import { DEFAULT_VALUE_NOTIFY_PUSH, typeSent } from './constant';
import { v4 } from 'uuid';

type InitialState = {
  campaignListFilter: ICampaignListFilter;
  activeStep: number;
  notifyPush: INotifyPush;
  notifySchedule: INotifySchedule;
  listCondition: ICondition[];
  typeSentNotify: string;
  isOpenFinishForm: boolean;
  isOpenFormEditStatus: boolean;
  idEditStatus: number;
  campaignSelect?: ICampaign;
};

const initialState: InitialState = {
  campaignListFilter: {},
  activeStep: 0,
  notifyPush: DEFAULT_VALUE_NOTIFY_PUSH,
  listCondition: [{ id: v4() }],
  notifySchedule: {},
  // typeSentNotify: typeSent[0].value,
  typeSentNotify: '',
  isOpenFinishForm: false,
  idEditStatus: 0,
  isOpenFormEditStatus: false,
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignListFilter: (state, action: PayloadAction<ICampaignListFilter>) => {
      state.campaignListFilter = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setNotifyPush: (state, action: PayloadAction<INotifyPush>) => {
      state.notifyPush = action.payload;
    },
    setNotifySchedule: (state, action: PayloadAction<INotifySchedule>) => {
      state.notifySchedule = action.payload;
    },
    setTypeSentNotify: (state, action: PayloadAction<string>) => {
      state.typeSentNotify = action.payload;
    },
    setListCondition: (state, action: PayloadAction<ICondition[]>) => {
      state.listCondition = action.payload;
    },
    setIsOpenFinishForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenFinishForm = action.payload;
    },
    setIsOpenFormEditStatus: (state, action: PayloadAction<boolean>) => {
      state.isOpenFormEditStatus = action.payload;
    },
    setIdEditStatus: (state, action: PayloadAction<number>) => {
      state.idEditStatus = action.payload;
    },
    setCampaignSelect: (state, action: PayloadAction<ICampaign | undefined>) => {
      state.campaignSelect = action.payload;
    },
    resetStateCampaign: (state) => {
      state.activeStep = initialState.activeStep;
      state.campaignListFilter = initialState.campaignListFilter;
      state.campaignSelect = initialState.campaignSelect;
      state.idEditStatus = initialState.idEditStatus;
      state.isOpenFinishForm = initialState.isOpenFinishForm;
      state.isOpenFormEditStatus = initialState.isOpenFormEditStatus;
      state.listCondition = initialState.listCondition;
      state.notifyPush = initialState.notifyPush;
      state.notifySchedule = initialState.notifySchedule;
      state.typeSentNotify = initialState.typeSentNotify;
    },
  },
});

export const {
  setCampaignListFilter,
  setActiveStep,
  setNotifyPush,
  setNotifySchedule,
  setTypeSentNotify,
  setListCondition,
  setIsOpenFinishForm,
  setIdEditStatus,
  setIsOpenFormEditStatus,
  setCampaignSelect,
  resetStateCampaign,
} = campaignSlice.actions;
export default campaignSlice.reducer;
