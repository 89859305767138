import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/common/redux/store';
import { ISearchCustomer } from './interface';
import { DEFAULT_VALUE_SEARCH_USER } from './constant';

type StateProps = {
  searchForm: ISearchCustomer;
  isOpenModalDelete: boolean;
  selectedRow: number[];
  isOpenModalPickUser: boolean;
  listPickedUser: number[];
};

const initialState: StateProps = {
  searchForm: DEFAULT_VALUE_SEARCH_USER,
  isOpenModalDelete: false,
  selectedRow: [],
  isOpenModalPickUser: false,
  listPickedUser: [],
};

export const pickUserSlice = createSlice({
  name: 'pick-user',
  initialState,
  reducers: {
    setSearchForm: (state, action: PayloadAction<ISearchCustomer>) => {
      state.searchForm = action.payload;
    },
    setIsOpenModalDelete: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalDelete = action.payload;
    },
    setSelectedRow: (state, action: PayloadAction<number[]>) => {
      state.selectedRow = action.payload;
    },
    setIsOpenModalPickUser: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalPickUser = action.payload;
    },
    setListPickedUser: (state, action: PayloadAction<number[]>) => {
      state.listPickedUser = action.payload;
    },
    resetPickUser: (state) => {
      state.isOpenModalDelete = initialState.isOpenModalDelete;
      state.isOpenModalPickUser = initialState.isOpenModalPickUser;
      state.listPickedUser = initialState.listPickedUser;
      state.searchForm = initialState.searchForm;
      state.selectedRow = initialState.selectedRow;
    },
  },
});

export const {
  setSearchForm,
  setIsOpenModalDelete,
  setIsOpenModalPickUser,
  setListPickedUser,
  setSelectedRow,
  resetPickUser,
} = pickUserSlice.actions;

export const searchFormSelector = (state: RootState) => state.pickUser.searchForm;

export const isOpenModalDeleteSelector = (state: RootState) =>
  state.pickUser.isOpenModalDelete;

export const selectedRowSelector = (state: RootState) => state.pickUser.selectedRow;

export const isOpenModalPickUserSelector = (state: RootState) =>
  state.pickUser.isOpenModalPickUser;

export const ListPickUserSelector = (state: RootState) => state.pickUser.listPickedUser;

export default pickUserSlice.reducer;
