import { API_FILTER_TAGS_ADMIN_V3 } from 'src/common/constants/apis';
import axiosInstance from 'src/common/utils/axios';
import {
  IParamsFiltersList,
} from './interface';

export const editFilter = async (param: any) => {
  const res = await axiosInstance.post<any>(`${API_FILTER_TAGS_ADMIN_V3}`, param);
  return res.data.response.data;
};

export const getFilterList = async (params: IParamsFiltersList) => {
  const res = await axiosInstance.get(`${API_FILTER_TAGS_ADMIN_V3}`, { params });
  return res.data.response.data;
};

export const getFilterDetail = async (id: string) => {
  const res = await axiosInstance.get(`${API_FILTER_TAGS_ADMIN_V3}`);
  return res.data.response.data;
};

export const createFilterDetail = async (param: any) => {
  const res = await axiosInstance.post<any>(`${API_FILTER_TAGS_ADMIN_V3}`, param);
  return res.data.response.data;
};
