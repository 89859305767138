import { useQuery } from 'react-query';
import { IParamsCoinList, IParamsCoinEditList } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getCoinEditList, getCoinList } from '../service';

export const useGetCoinList = (params: IParamsCoinList) => {
  const dataQuery = useQuery([QUERY_KEYS.COIN_LIST, params], () => getCoinList(params), {
    cacheTime: 2000,
  });

  return { ...dataQuery };
};

export const useGetCoinEditList = (params: IParamsCoinEditList) => {
  const dataQuery = useQuery([QUERY_KEYS.COIN_EDIT_LIST, params], () =>
    getCoinEditList(params)
  );
  return { ...dataQuery };
};
