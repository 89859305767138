import { TableCell, TableRow, Checkbox, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

type Props = {
  row: any;
  selected: boolean;
  onSelectRow: (checked: boolean) => void;
};

export default function GiftPreOrderRow({ row, onSelectRow, selected }: Props) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [dataRow, setDataRow] = useState<any>(row);
  const {
    giftId,
    createdAt,
    reservationPoint,
    gift,
    status,
    updatedAt,
    id,
    userId,
    dynamicData,
  } = dataRow;

  const handleOpenMenu = (category: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(category.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const renderStatus = (statusValue: string) => {
    let Item = null;
    switch (statusValue) {
      case 'PRE_ORDER':
        Item = (
          <Box
            sx={{
              backgroundColor: 'rgba(24, 144, 255, 0.16)',
              borderRadius: 6,
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: '#0C53B7', fontSize: 12, fontWeight: '700' }}
            >
              Đang đặt trước
            </Typography>
          </Box>
        );
        break;
      case 'EXCHANGED':
        Item = (
          <Box
            sx={{
              backgroundColor: 'rgba(84, 214, 44, 0.16)',
              borderRadius: 6,
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: '#229A16', fontSize: 12, fontWeight: '700' }}
            >
              Đã đổi quà
            </Typography>
          </Box>
        );
        break;
      case 'CANCEL':
        Item = (
          <Box
            sx={{
              backgroundColor: 'rgba(255, 72, 66, 0.16)',
              borderRadius: 6,
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: '#B72136', fontSize: 12, fontWeight: '700' }}
            >
              Đã hủy đặt trước
            </Typography>
          </Box>
        );
        break;
      default:
        Item = (
          <Box
            sx={{
              backgroundColor: 'rgba(205, 180, 179, 0.16)',
              borderRadius: 6,
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: '#181213', fontSize: 12, fontWeight: '700' }}
            >
              {statusValue}
            </Typography>
          </Box>
        );
        break;
    }
    return Item;
  };

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onChange={(e) => onSelectRow(e.target.checked)} />
        </TableCell>
        <TableCell align="left">{id}</TableCell>
        <TableCell align="left">
          {dayjs(createdAt).format('DD-MM-YYYY')}
          <br />
          {dayjs(createdAt).format('HH:mm:ss')}
        </TableCell>
        <TableCell align="left">{userId}</TableCell>
        <TableCell align="left">{reservationPoint}</TableCell>
        <TableCell align="left">
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={gift?.images[0]}
              alt={`banner-${id}`}
              width={64}
              height={64}
              style={{ objectFit: 'cover', borderRadius: 8 }}
            />
            <Typography sx={{ flex: 1, ml: 2 }}>{gift?.name}</Typography>
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: 150 }}>
          {renderStatus(status)}
        </TableCell>
        <TableCell align="left">
          {status === 'EXCHANGED' ? (
            <>
              {dayjs(updatedAt).format('DD-MM-YYYY')}
              <br />
              {dayjs(updatedAt).format('HH:mm:ss')}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="left">
          {status === 'CANCEL' ? (
            <>
              {dayjs(updatedAt).format('DD-MM-YYYY')}
              <br />
              {dayjs(updatedAt).format('HH:mm:ss')}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="left">
          {dayjs(dynamicData?.expiry_date).format('DD-MM-YYYY')}
          <br />
          {dayjs(dynamicData?.expiry_date).format('HH:mm:ss')}
        </TableCell>
      </TableRow>
    </>
  );
}
