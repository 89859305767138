import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface LoadingDialogProps {
  open: boolean;
  onClose: () => void;
  progress: number;
  isLoading: boolean;
  option: string;
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({
  open,
  onClose,
  progress,
  isLoading,
  option,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box position="relative">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: '16px',
            width: '100%',
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <img
            src="/assets/icons/import.png"
            alt="Excel"
            style={{ width: 216, height: 160 }}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: '4px',
              padding: '10px',
              margin: '20px 0',
              position: 'relative',
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>
                <img
                  src="https://techcommunity.microsoft.com/t5/image/serverpage/image-id/375416i783713B05CAD4A92/image-size/original?v=v2&px=-1"
                  alt="Excel"
                  style={{ width: 48, height: 36 }}
                />
              </Box>
              <Box display="flex" flexDirection="column" textAlign="left" sx={{ ml: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {option}
                </Typography>
                <Typography variant="caption">3.1 MB</Typography>
                <Box display="flex" alignItems="center">
                  {isLoading && progress == 100 && (
                    <Dialog open={isLoading} onClose={onClose} maxWidth="xs">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding={2}
                      >
                        <CircularProgress size={50} />
                        <Box>Hệ thống đang kiểm tra, vui lòng chờ trong giây lát...</Box>
                      </Box>
                    </Dialog>
                  )}
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ width: '220px', mr: 1 }}
                  />
                  <Box>{`${Math.round(progress)}%`}</Box>
                </Box>
              </Box>
            </Box>

            <IconButton size="small" sx={{ position: 'absolute', right: 0, top: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
