import { IHiddenTagForm } from './interface';

export enum STATUS {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
}
export enum GIFT_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const SEARCH_BY = {
  EVENT_NAME: 'EVENT_NAME',
  EVENT_GROUP_NAME: 'EVENT_GROUP_NAME',
};

export const TABLE_HEAD_GIFT_LIST = [
  { id: 'images', label: 'Images', align: 'center' },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    id: 'type',
    label: 'Type',
    align: 'center',
  },
  {
    id: 'sct',
    label: 'SCT',
    align: 'left',
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
  },
  {
    id: 'actualPoint',
    label: 'Actual Point',
    align: 'left',
  },
  {
    id: 'money',
    label: 'Money',
    align: 'left',
  },
  {
    id: 'active',
    label: 'Active',
    align: 'left',
  },
];

export const TABLE_HEAD_GIFT_CATEGORY_LIST = [
  { id: 'priority', label: 'Priority', align: 'center' },
  {
    id: 'name',
    label: 'Category Name',
    align: 'left',
  },
  {
    id: 'active',
    label: 'Active',
    align: 'left',
  },
];

export const DEFAULT_VALUE_GIFT_CATEGORY_FORM: IHiddenTagForm = {
  name: '',
  priority: null,
  isActive: true,
  code: '',
};
