import { LoadingButton } from '@mui/lab';
import { Button, Card, Grid, MenuItem, Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/common/components/hook-form';
// components
import { PRE_ORDER_STATUS_LIST } from 'src/gift/common/constant';

// ----------------------------------------------------------------------
export const initialFilter = {
  name: '',
  statuses: [],
};

export const GiftPreOrderTableToolbar = (props: { handleSearch: Function }) => {
  const { handleSearch } = { ...props };
  const methods = useForm({
    defaultValues: initialFilter,
  });
  const { control, handleSubmit, reset } = methods;

  const onSubmit = (data: any) => {
    handleSearch({
      name: data.name.length > 0 ? data.name : undefined,
      preOrderStatus: data.statuses.length > 0 ? data.statuses.join(',') : undefined,
    });
  };
  const handleResetSearch = () => {
    reset(initialFilter);
    handleSearch({});
  };
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ px: 2 }}>
          <Grid container spacing={3} py="30px">
            <Grid item xs={2}>
              <Controller
                name="statuses"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Status"
                    select
                    SelectProps={{ multiple: true }}
                    defaultValue={[]}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  >
                    {PRE_ORDER_STATUS_LIST.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={3} marginTop="10px" mx={4}>
                <LoadingButton type="submit" variant="contained" size="medium">
                  Tìm kiếm
                </LoadingButton>
                <Button
                  type="reset"
                  variant="contained"
                  color="inherit"
                  size="medium"
                  onClick={handleResetSearch}
                >
                  Xóa
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </FormProvider>
    </>
  );
};
