import { useMutation } from 'react-query';
import { IOrderAdminCallback, IParamsOrderShowShip } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { patchOrderShowShip } from '../service';
import useTable from 'src/common/hooks/useTable';
import { useGetOrderList } from './useGetOrderList';

export const usePatchShowShip = (callback: IOrderAdminCallback) => {
  const propsTable = useTable();
  const { refetch } = useGetOrderList({
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });

  return useMutation({
    mutationFn: patchOrderShowShip,
    onSuccess: async (rs, _variables) => {
      callback.onSuccess && callback.onSuccess(rs);
      refetch();
    },
    onError: (error, _variables) => {
      callback.onError && callback.onError(error);
    },
  });
};
