import {
  API_ORDER_GIFTS_V2,
  API_COIN_LIST,
  API_COIN_IMPORT,
} from 'src/common/constants/apis';
import axiosInstance from 'src/common/utils/axios';
import { IParamsCoinEditList, IParamsCoinList } from './interface';
import { CommonResponseAPI } from 'src/common/constants/common.interfaces';

export const getCoinList = (params: IParamsCoinList) => {
  return axiosInstance.get<any, CommonResponseAPI<any>>(`${API_COIN_LIST}`, {
    params,
  });
};

export const getCoinEditList = (params: IParamsCoinEditList) => {
  return axiosInstance.get<any, CommonResponseAPI<any>>(`${API_ORDER_GIFTS_V2}`, {
    params,
  });
};

export const importCoinList = (formData: FormData) => {
  return axiosInstance.post(`${API_COIN_IMPORT}`, formData);
};
