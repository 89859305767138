import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import GiftCategoryListHeader from './components/GiftCategoryListHeader';
import GiftCategoryListTable from './components/table/GiftCategoryListTable';
import { GiftCategoryTableToolbar } from './components/GiftCategoryTableToolbar';
import { useEffect, useState } from 'react';
import useTable from 'src/common/hooks/useTable';
import { IParamsGiftCategoriesList } from '../common/interface';
import { useGetGiftCategories } from '../common/hooks/useGetGiftCategories';

export default function GiftCategoriesList() {
  const { themeStretch } = useSettings();

  const propsTable = useTable({
    defaultRowsPerPage: 100,
  });
  const [searchParams, setSearchParams] = useState<IParamsGiftCategoriesList>({
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });
  const { data, isLoading, refetch } = useGetGiftCategories({
    ...searchParams,
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });

  const handleSearch = (dataSearch: any) => {
    refetch();
    propsTable.setPage(0);
    setSearchParams(dataSearch);
  };

  useEffect(() => {
    refetch();
    propsTable.setPage(0);
  }, []);

  return (
    <Page title={BREADCUMBS.GIFT_CATEGORY}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <GiftCategoryListHeader />
        {/* <GiftCategoryTableToolbar handleSearch={handleSearch} /> */}
        <GiftCategoryListTable
          data={data?.data?.response}
          isLoading={isLoading}
          {...propsTable}
        />
      </Container>
    </Page>
  );
}
