import { TableCell, MenuItem, TableRow, Checkbox, Switch } from '@mui/material';
import { useState } from 'react';
import { TableMoreMenu } from 'src/common/components/table';
import { GiftCategory } from 'src/gift/common/interface';
import { editGiftCategory } from 'src/gift/common/service';

type Props = {
  row: GiftCategory;
  onDeleteRow: VoidFunction;
  onEditRow: VoidFunction;
  selected: boolean;
  onSelectRow: (checked: boolean) => void;
};

export default function GiftCategoryRow({
  row,
  // onDeleteRow,
  onEditRow,
  onSelectRow,
  selected,
}: Props) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [dataRow, setDataRow] = useState<GiftCategory>(row);
  const { name, code, priority, isActive } = dataRow;

  const handleOpenMenu = (category: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(category.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleOnChangeStatus = async (active: boolean) => {
    const isActive = active;
    const res = await editGiftCategory(code, { ...row, isActive });
    if (res.response.code === 200) {
      setDataRow({ ...dataRow, isActive });
    }
  };

  return (
    <>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onChange={(e) => onSelectRow(e.target.checked)} />
        </TableCell> */}

        <TableCell align="center">{priority}</TableCell>

        <TableCell
          sx={{
            '&:hover': { color: '#D5B4B4', cursor: 'pointer' },
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 300,
            minWidth: 150,
          }}
          onClick={onEditRow}
        >
          {name}
        </TableCell>

        <TableCell
          sx={{
            '&:hover': { color: '#D5B4B4', cursor: 'pointer' },
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 300,
            minWidth: 150,
          }}
          onClick={onEditRow}
        >
          {code}
        </TableCell>
        <TableCell align="left">
          <Switch
            size="medium"
            checked={isActive}
            onChange={(e) => {
              handleOnChangeStatus(e.target.checked);
            }}
          />
        </TableCell>
        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onClose={handleCloseMenu}
            onOpen={handleOpenMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  Chỉnh sửa
                </MenuItem>
              </>
            }
          ></TableMoreMenu>
        </TableCell>
      </TableRow>
    </>
  );
}
