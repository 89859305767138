import { FileRejection } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';

// ----------------------------------------------------------------------

type Props = {
  fileRejections: FileRejection[];
};

export default function RejectionFiles({ fileRejections }: Props) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = getFileData(file);

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size ? fData(size) : ''}
            </Typography>

            {errors.map((error) => (
              <Box key={error.code} component="li" sx={{ typography: 'caption' }}>
                {error.message}
              </Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
  // return (
  //   <Paper
  //     variant="outlined"
  //     sx={{
  //       py: 1,
  //       px: 2,
  //       mt: 3,
  //       borderColor: 'error.light',
  //       bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
  //     }}
  //   >
  //     {fileRejections.map(({ file, errors }) => {
  //       const { path, size } = getFileData(file);

  //       return (
  //         <Box key={path} sx={{ my: 1 }}>
  //           <Typography variant="subtitle2" noWrap color={'#FF4842'}>
  //             {path} - {size ? fData(size) : ''}
  //           </Typography>

  //           {errors.map((error) => {
  //             if (error.code === 'file-too-large') {
  //               return (
  //                 <Box
  //                   key={error.code}
  //                   color={'#FF4842'}
  //                   component="li"
  //                   sx={{ typography: 'caption' }}
  //                 >
  //                   Tệp lớn hơn 3145728 byte
  //                 </Box>
  //               );
  //             }
  //             return (
  //               <Box key={error.code} component="li" sx={{ typography: 'caption' }}>
  //                 {error.message}
  //               </Box>
  //             );
  //           })}
  //         </Box>
  //       );
  //     })}
  //   </Paper>
  // );
}
