import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import Iconify from 'src/common/components/Iconify';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

const OrderShipHeader: React.FC = () => {
  const location = useLocation();

  const currentId = location.pathname.slice(
    location.pathname.lastIndexOf('/') + 1,
    location.pathname.length
  );

  return (
    <HeaderBreadcrumbs
      heading={BREADCUMBS.ORDER_MANAGE}
      links={[
        {
          name: BREADCUMBS.ORDER_MANAGE,
          href: PATH_DASHBOARD.orderAdmin.root,
        },
        { name: BREADCUMBS.ORDER_SHOW_SHIP },
      ]}
      
    />
  );
};

export default OrderShipHeader;
