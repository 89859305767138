import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

export default function FilterEditHeader() {
  const params = useParams();
  const id = params?.id;
  return (
    <>
      <HeaderBreadcrumbs
        heading={id ? BREADCUMBS.FILTER_EDIT : BREADCUMBS.FILTER_CREATE}
        links={[
          {
            name: BREADCUMBS.FILTER_MANAGE,
            href: PATH_DASHBOARD.filterAdmin.root,
          },
          { name: id ? BREADCUMBS.FILTER_EDIT : BREADCUMBS.FILTER_CREATE },
        ]}
      />
    </>
  );
}
