import { Button, Paper, Stack, Switch, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/common/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useShowSnackbar from 'src/common/hooks/useMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { LoadingButton } from '@mui/lab';
import { giftCategoryFormSchema } from 'src/gift/common/schema';
import { DEFAULT_VALUE_GIFT_CATEGORY_FORM } from 'src/gift/common/constant';
import { IGiftCategoryEditDto, IGiftCategoryForm } from 'src/gift/common/interface';
import { useEditGiftCategory } from 'src/gift/common/hooks/useEditGiftCategory';
import { useGetGiftCategoryById } from 'src/gift/common/hooks/useGetGiftCategoryById';
import { createGiftCategory } from 'src/gift/common/service';

export default function GiftCategoryEditForm() {
  const methods = useForm<IGiftCategoryForm>({
    resolver: yupResolver(giftCategoryFormSchema),
    defaultValues: DEFAULT_VALUE_GIFT_CATEGORY_FORM,
  });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = methods;
  const { showSuccessSnackbar, showErrorSnackbar } = useShowSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: dataGiftCategory } = useGetGiftCategoryById(id);
  const { editGiftCategory } = useEditGiftCategory();

  const onSubmit = async (data: IGiftCategoryForm) => {
    const dataSubmit: IGiftCategoryEditDto = id
      ? {
          ...dataGiftCategory,
          name: data.name,
          code: data.code.trim(),
          isActive: data.isActive,
          priority: parseInt(data.priority, 10),
        }
      : {
          name: data.name,
          code: data.code.trim(),
          isActive: data.isActive,
          priority: parseInt(data.priority, 10),
        };

    try {
      const res = id
        ? await editGiftCategory(id, dataSubmit)
        : await createGiftCategory(dataSubmit);
      if (res?.response?.code === 200) {
        showSuccessSnackbar(
          id ? 'Cập nhật danh mục thành công' : 'Tạo danh mục thành công'
        );
        navigate(PATH_DASHBOARD.giftAdmin.categoryList);
      } else
        showErrorSnackbar(id ? 'Cập nhật danh mục thất bại' : 'Tạo danh mục thất bại');
    } catch (e) {
      showErrorSnackbar(
        e.response?.data?.response?.msg ||
          (id ? 'Cập nhật danh mục thất bại' : 'Tạo danh mục thất bại')
      );
    }
  };

  useEffect(() => {
    if (dataGiftCategory) {
      const { priority, isActive, name, code } = dataGiftCategory;
      reset({
        name,
        priority,
        isActive,
        code,
      });
    }
  }, [dataGiftCategory]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 2 }}>
        <Stack spacing={2}>
          <RHFTextField name="name" label="Name *" InputLabelProps={{ shrink: true }} />
          <RHFTextField
            name="code"
            label="Code *"
            InputLabelProps={{ shrink: true }}
            disabled={!!id}
          />
          <RHFTextField
            name="priority"
            label="Priority"
            type="number"
            InputLabelProps={{ shrink: true }}
          />
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography>Active</Typography>{' '}
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => <Switch {...field} checked={field.value} />}
            />
          </Stack>
          <Stack alignSelf={'flex-end'} display="flex" columnGap={2} direction="row">
            <Button
              variant="contained"
              color="inherit"
              onClick={() => navigate(PATH_DASHBOARD.giftAdmin.categoryList)}
            >
              Hủy
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {id ? 'Lưu' : 'Tạo mới'}
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </FormProvider>
  );
}
