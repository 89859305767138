// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Radio,
  RadioGroup,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
} from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  options: {
    label: string;
    value: any;
  }[];
};

type Props = IProps & RadioGroupProps;

export default function RHFRadioGroup({ name, options, ...other }: Props) {
  const { control } = useFormContext();
  const [value, setValue] = useState<any>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (field.value !== undefined) setValue(field.value);
        return (
          <div>
            <RadioGroup
              {...field}
              row
              value={value}
              onChange={(event, value) => {
                field.onChange(value);
                setValue(value);
              }}
              {...other}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>

            {!!error && (
              <FormHelperText error sx={{ px: 2 }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}
