// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

type Props = {
  hasFile: boolean;
  showPreview?: boolean;
  placeholder?: string;
  isDragActive?: boolean;
};

export default function BlockContent({
  hasFile,
  placeholder,
  showPreview = true,
  isDragActive,
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {hasFile ? (
        <>
          {showPreview ? (
            <Box sx={{ minHeight: '160px' }} />
          ) : (
            <Box sx={{ minHeight: '40px' }}>
              <Typography variant="caption" color="textSecondary">
                {isDragActive ? 'Thả ảnh vào để thêm' : 'Kéo,thả ảnh hoặc click vào đây để thêm'}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <UploadIllustration sx={{ width: 220 }} />
          <Box sx={{ p: 3 }}>
            <Typography gutterBottom variant="h5">
              {placeholder || 'Drop or Select file'}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Drop files here or click&nbsp;
              <Typography
                variant="body2"
                component="span"
                sx={{ color: 'primary.main', textDecoration: 'underline' }}
              >
                browse
              </Typography>
              &nbsp;thorough your machine
            </Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
}
