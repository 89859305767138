import { IFilterForm } from './interface';

export enum STATUS {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
}
export enum GIFT_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const SEARCH_BY = {
  EVENT_NAME: 'EVENT_NAME',
  EVENT_GROUP_NAME: 'EVENT_GROUP_NAME',
};

export const TABLE_HEAD_GIFT_LIST = [
  { id: 'images', label: 'Images', align: 'center' },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    id: 'type',
    label: 'Type',
    align: 'center',
  },
  {
    id: 'sct',
    label: 'SCT',
    align: 'left',
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
  },
  {
    id: 'actualPoint',
    label: 'Actual Point',
    align: 'left',
  },
  {
    id: 'money',
    label: 'Money',
    align: 'left',
  },
  {
    id: 'active',
    label: 'Active',
    align: 'left',
  },
];

export const TABLE_HEAD_FILTER_LIST = [
  { id: 'index', label: 'STT', align: 'left' },
  {
    id: 'name',
    label: 'Tên bộ lọc chính',
    align: 'left',
  },
  {
    id: 'value',
    label: 'Giá trị',
    align: 'left',
  },
  {
    id: 'priority',
    label: 'Độ ưu tiên',
    align: 'left',
  },

  {
    id: 'status',
    label: 'Trạng thái',
    align: 'left',
  },
];

export const DEFAULT_VALUE_FILTER_FORM: IFilterForm = {
  name: '',
  priority: '',
  isActive: false,
  code: '',
};
