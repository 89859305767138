import {
  API_BRAND_GOTIT_V2,
  API_CATEGORY_FILTER_ADMIN_V2,
  API_CATEGORY_GOTIT_V2,
  API_COURSES_BKID_V2,
  API_HIDDEN_TAGS_ADMIN_V3,
  API_LIST_BADGES_V2,
  API_PRODUCT_GOTIT_V2,
} from 'src/common/constants/apis';
import axiosInstance from 'src/common/utils/axios';
import { IHiddenTagEditDto, IHiddenTagCreateDto, IResHiddenTagCreate } from './interface';

export const getCategories = () => {
  return axiosInstance.get(`${API_CATEGORY_FILTER_ADMIN_V2}`, {
    params: { size: 200 },
  });
};

export const getGotItBrands = () => {
  return axiosInstance.get(`${API_BRAND_GOTIT_V2}`);
};

export const getGotItCategories = () => {
  return axiosInstance.get(`${API_CATEGORY_GOTIT_V2}`);
};

export const getGotItProducts = (params: any) => {
  return axiosInstance.get(`${API_PRODUCT_GOTIT_V2}`, { params });
};

export const getCoursesBkids = () => {
  return axiosInstance.get(`${API_COURSES_BKID_V2}`);
};

export const getBadges = () => {
  return axiosInstance.get(`${API_LIST_BADGES_V2}`);
};

export const getHiddenTagsV3 = (params: any) => {
  return axiosInstance.get(`${API_HIDDEN_TAGS_ADMIN_V3}`, {
    params,
  });
};

export const createHiddenTag = async (data: IHiddenTagCreateDto) => {
  const res = await axiosInstance.post(`${API_HIDDEN_TAGS_ADMIN_V3}`, data);
  return res.data.response;
};

export const editHiddenTag = async (hiddenTagId: string, data: IHiddenTagEditDto) => {
  const res = await axiosInstance.patch(
    `${API_HIDDEN_TAGS_ADMIN_V3}/${hiddenTagId}`,
    data
  );
  return res.data.response;
};

export const getHiddenTagByIdV3 = async (id: string) => {
  const res = await axiosInstance.get(`${API_HIDDEN_TAGS_ADMIN_V3}/${id}`);
  return res.data.response.data;
};
