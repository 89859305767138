import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import Iconify from 'src/common/components/Iconify';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

export default function BannerListHeader() {
  return (
    <>
      <HeaderBreadcrumbs
        heading={BREADCUMBS.GIFT_CATEGORY}
        links={[
          {
            name: BREADCUMBS.GIFT_MANAGE,
            href: PATH_DASHBOARD.giftAdmin.list,
          },
          { name: BREADCUMBS.GIFT_CATEGORY },
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            to={PATH_DASHBOARD.giftAdmin.categoryCreate}
            component={RouterLink}
          >
            {BREADCUMBS.GIFT_CATEGORY_CREATE}
          </Button>
        }
      />
    </>
  );
}
