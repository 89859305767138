import { Button, Grid, Paper, Stack, Switch, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/common/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useShowSnackbar from 'src/common/hooks/useMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { LoadingButton } from '@mui/lab';
import { giftCategoryFormSchema } from 'src/hiddenTag/common/schema';
import { DEFAULT_VALUE_GIFT_CATEGORY_FORM } from 'src/hiddenTag/common/constant';
import { IHiddenTagEditDto, IHiddenTagForm } from 'src/hiddenTag/common/interface';
import { useEditHiddenTag } from 'src/hiddenTag/common/hooks/useEditHiddenTag';
import { useGetHiddenTagById } from 'src/hiddenTag/common/hooks/useGetHiddenTagById';
import { createHiddenTag } from 'src/hiddenTag/common/service';

export default function HiddenTagEditForm() {
  const methods = useForm<IHiddenTagForm>({
    resolver: yupResolver(giftCategoryFormSchema),
    defaultValues: DEFAULT_VALUE_GIFT_CATEGORY_FORM,
  });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = methods;
  const { showSuccessSnackbar, showErrorSnackbar } = useShowSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  // const [inputValue, setInputValue] = useState('');

  // const handleInputChange = (event: any) => {
  //   setInputValue(event.target.value);
  // };

  const { data: dataGiftCategory } = useGetHiddenTagById(id);
  const { editHiddenTag } = useEditHiddenTag();

  const onSubmit = async (data: IHiddenTagForm) => {
    if (data.code.trim() === '') {
      showErrorSnackbar('Code không được để trống');
      return;
    }
    const dataSubmit: IHiddenTagEditDto = id
      ? {
          ...dataGiftCategory,
          name: data.name,
          code: data.code,
          isActive: data.isActive,
          priority: data.priority,
        }
      : {
          name: data.name,
          code: data.code,
          isActive: data.isActive,
          priority: data.priority,
        };
    try {
      const res = id
        ? await editHiddenTag(id, dataSubmit)
        : await createHiddenTag(dataSubmit);
      if (res?.code === 200) {
        showSuccessSnackbar(
          id ? 'Cập nhật hidden tag thành công' : 'Tạo hidden tag thành công'
        );
        navigate(PATH_DASHBOARD.hiddenTagsAdmin.root);
      } else
        showErrorSnackbar(
          id ? 'Cập nhật hidden tag thất bại' : 'Tạo hidden tag thất bại'
        );
    } catch (e) {
      showErrorSnackbar(
        e.response?.data?.response?.msg ||
          (id ? 'Cập nhật hidden tag thất bại' : 'Tạo hidden tag thất bại')
      );
    }
  };

  useEffect(() => {
    if (dataGiftCategory) {
      const { priority, isActive, name, code } = dataGiftCategory;
      reset({
        name,
        priority,
        isActive,
        code,
      });
    }
  }, [dataGiftCategory]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 2 }}>
        <Stack spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <RHFTextField
                name="name"
                label="Tên tag *"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography>Active</Typography>{' '}
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => <Switch {...field} checked={field.value} />}
                />
              </Stack>
            </Grid>
          </Grid>

          <RHFTextField name="code" label="Code *" InputLabelProps={{ shrink: true }} />
          <RHFTextField
            name="priority"
            label="Priority *"
            type="number"
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </Paper>
      <Stack
        alignSelf={'flex-end'}
        display="flex"
        columnGap={2}
        direction="row"
        mt={2}
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="inherit"
          onClick={() => navigate(PATH_DASHBOARD.hiddenTagsAdmin.root)}
        >
          Hủy
        </Button>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {id ? 'Lưu' : 'Tạo mới'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
