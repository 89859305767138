import { API_ORDER_GIFTS_V2, API_ORDER_V2 } from 'src/common/constants/apis';
import axiosInstance from 'src/common/utils/axios';
import {
  IParamsOrderEditList,
  IParamsOrderList,
  IParamsOrderShowShip,
} from './interface';
import { CommonResponseAPI } from 'src/common/constants/common.interfaces';

export const getOrderList = (params: IParamsOrderList) => {
  return axiosInstance.get<any, CommonResponseAPI<any>>(`${API_ORDER_V2}`, {
    params,
  });
};

export const getOrderEditList = (params: IParamsOrderEditList) => {
  return axiosInstance.get<any, CommonResponseAPI<any>>(`${API_ORDER_GIFTS_V2}`, {
    params,
  });
};

export const patchOrderShowShip = (params: IParamsOrderShowShip) => {
  return axiosInstance.patch<any, CommonResponseAPI<any>>(
    `${API_ORDER_V2}/${params.osOrderId}/show-shipping-fee?isShowShippingFee=${params.isShowShippingFee}`
  );
};

export const importOrderUpdate = (formData: FormData) => {
  return axiosInstance.post(`${API_ORDER_V2}/import/create`, formData);
};

export const importOrderReDelivery = (formData: FormData) => {
  return axiosInstance.post(`${API_ORDER_V2}/import/full-redelivery`, formData);
};

export const importOrderDeliveryProvider = (formData: FormData) => {
  return axiosInstance.post(`${API_ORDER_V2}/import/delivery-provider`, formData);
};

export const importOrderOrderGift = (formData: FormData) => {
  return axiosInstance.post(`${API_ORDER_V2}/import/order-gift`, formData);
};

export const importOrderPartDelivery = (formData: FormData) => {
  return axiosInstance.post(`${API_ORDER_V2}/import/part-redelivery`, formData);
};

export const exportOrderList = (params: any) => {
  return axiosInstance.post(`${API_ORDER_V2}/export/orders`, params, {
    responseType: 'blob',
  });
};
