import {
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from 'src/common/components/table';
import LoadingTableSkeleton from 'src/common/components/LoadingTableSkeleton';
import { useSelectMultiple } from 'src/common/hooks/useSelectMultiple';
import Iconify from 'src/common/components/Iconify';
import HiddenTagRow from './HiddenTagRow';
import { ConfirmModal } from 'src/common/components/modal/ConfirmModal';
import { FC, useState } from 'react';
import { replacePathParams } from 'src/common/utils/replaceParams';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import {
  TABLE_HEAD_GIFT_LIST,
  TABLE_HEAD_HIDDEN_TAG_LIST,
} from 'src/gift/common/constant';
import { IResGiftCategoryList } from 'src/gift/common/interface';

export interface FilterListTableProps {
  data: IResGiftCategoryList | undefined;
  isLoading: boolean;
  dense: boolean;
  order: any;
  page: number;
  setPage: any;
  orderBy: any;
  rowsPerPage: any;
  selected: any;
  setSelected: any;
  onSelectRow: any;
  onSelectAllRows: any;
  onSort: any;
  onChangePage: any;
  onChangeDense: any;
  onChangeRowsPerPage: any;
}

const HiddenTagListTable: FC<FilterListTableProps> = ({
  data,
  isLoading,
  page,
  rowsPerPage,
  dense,
  onChangeDense,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const listGiftCategories = data?.data || [];
  const totalItems = data?.data?.length || 0;

  const {
    isCheckedAll,
    reset: resetSelect,
    selectedIds,
    handleSelectItem,
    handleCheckAll,
  } = useSelectMultiple<string>(
    listGiftCategories.map((item) => item.code),
    page + 1
  );

  const onDeleteMultiple = () => {
    // idsDelete.forEach((id) =>
    //   mutate(
    //     { id },
    //     {
    //       onSuccess: (res) => {
    //         if (res?.data?.meta?.status === 1000) {
    //           showSuccessSnackbar(`Xóa category thành công`);
    //           queryClient.invalidateQueries();
    //         } else showErrorSnackbar(`Xóa category thất bại`);
    //       },
    //       onError: () => {
    //         showErrorSnackbar(`Xóa category thất bại`);
    //       },
    //     }
    //   )
    // );
    resetSelect();
  };

  const handleEditRow = (id: string) => {
    navigate(replacePathParams(PATH_DASHBOARD.hiddenTagsAdmin.hiddenTagsEdit, { id }));
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <ConfirmModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={onDeleteMultiple}
        type={'delete'}
        text={'Bạn có chắc muốn xóa category này?'}
      />
      <TableContainer sx={{ position: 'relative' }}>
        {!!selectedIds.length && (
          <TableSelectedActions
            dense={dense}
            isSelectAll={isCheckedAll}
            numSelected={selectedIds.length}
            rowCount={listGiftCategories.length}
            onSelectAllRows={handleCheckAll}
            actions={
              <Tooltip title="Delete">
                <IconButton color="primary">
                  <Iconify icon={'eva:trash-2-outline'} />
                </IconButton>
              </Tooltip>
            }
          />
        )}
        <Table size={dense ? 'small' : 'medium'}>
          <TableHeadCustom
            headLabel={TABLE_HEAD_HIDDEN_TAG_LIST}
            rowCount={listGiftCategories.length}
            isSelectAll={isCheckedAll}
            numSelected={selectedIds.length}
          />
          <TableBody>
            {listGiftCategories.map((row, idx) => (
              <HiddenTagRow
                key={row.code}
                row={row}
                onEditRow={() => handleEditRow(row.code)}
                onDeleteRow={() => {}}
                selected={selectedIds.includes(row.code)}
                onSelectRow={(e) => {
                  handleSelectItem(row.code, e);
                }}
                idx={idx + 1}
              />
            ))}
            {isLoading && (
              <LoadingTableSkeleton
                row={rowsPerPage}
                column={TABLE_HEAD_GIFT_LIST.length + 1}
              />
            )}
            <TableNoData isNotFound={!isLoading && !listGiftCategories?.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          // rowsPerPageOptions={[5, 10, 15]}
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={lodash.isEmpty(totalItems) ? (totalItems as number) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label={'Thu gọn'}
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>
    </Paper>
  );
};
export default HiddenTagListTable;
