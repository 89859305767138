import { useState, FC } from 'react';
import { Card, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/common/components/hook-form';
import Iconify from 'src/common/components/Iconify';
import { orderDeliveryProviderIdList, orderTypeList } from 'src/orders/common/constant';
import useTable from 'src/common/hooks/useTable';
const orderStatusList = [
  { label: 'Tất cả', value: 'ALL' },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'IN_ACTIVE',
  },
];
export const initialFilter = {
  statusCode: orderStatusList[0].value,
  deliveryProviderId: orderDeliveryProviderIdList[0].value,
  typeCode: orderTypeList[0].value,
  createdDate: null,
  keyword: '',
};

const FilterListFilter: FC<{ handleSearch: Function }> = ({ handleSearch }) => {
  const propsTable = useTable();

  const methods = useForm({
    defaultValues: initialFilter,
  });

  const onSubmit = (data: any) => {
    const params = {
      statusCode: data.statusCode === orderStatusList[0].value ? '' : data.statusCode,
      deliveryProviderId:
        data.deliveryProviderId === orderDeliveryProviderIdList[0].value
          ? ''
          : data.deliveryProviderId,
      typeCode: data.typeCode === orderTypeList[0].value ? '' : data.typeCode,
      createdDate: data.createdDate ? data.createdDate : null,
      keyword: data.keyword ? data.keyword : '',
    };
    handleSearch(params);
  };

  const handleResetSearch = () => {
    reset(initialFilter);
    handleSearch({
      page: propsTable.page,
      size: propsTable.rowsPerPage,
      statusCode: '',
      deliveryProviderId: '',
      typeCode: '',
      createdDate: null,
      keyword: '',
    });
  };

  const { control, handleSubmit, reset } = methods;

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ px: 2 }}>
          <Grid container spacing={3} py="30px">
            <Grid item xs={2}>
              <Controller
                name="statusCode"
                control={control}
                render={({ field }) => (
                  <TextField fullWidth label="Trạng thái" select {...field}>
                    {orderStatusList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={10}>
              <Controller
                name="keyword"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Tìm kiếm theo tên bộ lọc chính'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
      </FormProvider>
    </>
  );
};

export default FilterListFilter;
