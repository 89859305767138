import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import FilterListHeader from './components/FilterListHeader';
import FilterListTable from './components/table/FilterListTable';
import { useEffect, useState } from 'react';
import useTable from 'src/common/hooks/useTable';
import { IParamsFiltersList } from '../common/interface';
import { useGetFilters } from '../common/hooks/useGetFilters';
import FilterListFilter from './components/FilterListFilter';

export default function FiltersList() {
  const { themeStretch } = useSettings();

  const propsTable = useTable({
    defaultRowsPerPage: 50,
  });
  const [searchParams, setSearchParams] = useState<IParamsFiltersList>({
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });
  const { data, isLoading, refetch } = useGetFilters({
    ...searchParams,
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });

  const handleSearch = (dataSearch: any) => {
    refetch();
    propsTable.setPage(0);
    setSearchParams(dataSearch);
  };


  return (
    <Page title={BREADCUMBS.FILTER_MANAGE}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FilterListHeader />
        {/* <GiftCategoryTableToolbar handleSearch={handleSearch} /> */}
        <FilterListFilter handleSearch={handleSearch} />
        <FilterListTable data={data} isLoading={isLoading} {...propsTable} />
      </Container>
    </Page>
  );
}
