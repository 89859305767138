import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getHiddenTagByIdV3 } from '../service';
import { DEFAULT_VALUE_GIFT_CATEGORY_FORM } from '../constant';

export const useGetHiddenTagById = (id: string | undefined) => {
  return useQuery(
    [QUERY_KEYS.HIDDEN_TAG_BY_ID, id],
    () => (id ? getHiddenTagByIdV3(id) : DEFAULT_VALUE_GIFT_CATEGORY_FORM),
    {
      cacheTime: 0,
    }
  );
};
