import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Category, StateProps } from './interface';
import { RootState } from 'src/common/redux/store';

export const initialState: StateProps = {
  categories: [],
  keySearch: '',
  categoryId: '',
  type: '',
  active: '',
  tierCode: '',
  gotItBrands: [],
  gotItCategories: [],
};

export const manageGiftSlice = createSlice({
  name: 'manage-gift',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setKeySearch: (state, action: PayloadAction<string>) => {
      state.keySearch = action.payload;
    },
    setCategoryId: (state, action: PayloadAction<string>) => {
      state.categoryId = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setActive: (state, action: PayloadAction<string>) => {
      state.active = action.payload;
    },
    setTierCode: (state, action: PayloadAction<string>) => {
      state.tierCode = action.payload;
    },
    setGotItBrands: (state, action: PayloadAction<any>) => {
      state.gotItBrands = action.payload;
    },
    setGotItCategories: (state, action: PayloadAction<any>) => {
      state.gotItCategories = action.payload;
    },
  },
});

export const {
  setCategories,
  setKeySearch,
  setCategoryId,
  setType,
  setActive,
  setTierCode,
  setGotItBrands,
  setGotItCategories,
} = manageGiftSlice.actions;

export const selectCategories = (state: RootState) => state.gift.categories;
export const selectKeySearch = (state: RootState) => state.gift.keySearch;
export const selectGotItBrands = (state: RootState) => state.gift.gotItBrands;
export const selectGotItCategories = (state: RootState) => state.gift.gotItCategories;

export default manageGiftSlice.reducer;
