import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import Iconify from 'src/common/components/Iconify';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

export default function HiddenTagListHeader() {
  return (
    <>
      <HeaderBreadcrumbs
        heading={BREADCUMBS.HIDDEN_TAG_MANAGE}
        links={[
          {
            name: 'Quản lý User Tag',
            href: PATH_DASHBOARD.hiddenTagsAdmin.root,
          },
          {
            name: 'Danh sách',
          },
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            to={PATH_DASHBOARD.hiddenTagsAdmin.hiddenTagsCreate}
            component={RouterLink}
          >
            Tạo mới
          </Button>
        }
      />
    </>
  );
}
