import React, { useRef, useState } from 'react';
import { Box, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface FileDialogProps {
  open: boolean;
  onClose: () => void;
  onImportFile: (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    mutate: any
  ) => void;
  option: string;
}

const FileDialog: React.FC<FileDialogProps> = ({
  open,
  onClose,
  onImportFile,
  option,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    onImportFile(e, option);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box position="relative">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            border: dragging ? '2px dashed green' : '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '16px',
            width: '100%',
            maxWidth: '400px',
            margin: '0 auto',
            backgroundColor: dragging ? '#f0f0f0' : 'transparent',
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <img
            src="/assets/icons/import.png"
            alt="Excel"
            style={{ width: 216, height: 160, userSelect: 'none' }}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
            Select files
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Drop files here or{' '}
            <span
              style={{ color: 'green', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => fileInputRef?.current?.click()}
            >
              browse
            </span>{' '}
            through your machine
          </Typography>
          <input
            type="file"
            hidden
            multiple
            ref={fileInputRef}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onImportFile(event, option)
            }
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FileDialog;
