import { SyntheticEvent, useState, FC } from 'react';
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  TextField,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/common/components/hook-form';
import Iconify from 'src/common/components/Iconify';
import { FORMAT_DATE_NEWS } from 'src/common/constants/common.constants';

import { CoinStatusList } from 'src/coin/common/constant';
import useTable from 'src/common/hooks/useTable';

export const initialFilter = {
  statusCode: CoinStatusList[0].value,
  typeCode: CoinStatusList[0].value,
  createdDate: null,
  keyword: '',
};

const CoinListFilter: FC<{ handleSearch: Function }> = ({ handleSearch }) => {
  const [currentTab, setCurrentTab] = useState<string>(CoinStatusList[0].value);

  const propsTable = useTable();

  const methods = useForm({
    defaultValues: initialFilter,
  });

  const onSubmit = (data: any) => {
    const params = {};
    handleSearch(params);
  };

  const handleResetSearch = () => {
    reset({ ...initialFilter, statusCode: currentTab });
    handleSearch({
      page: propsTable.page,
      size: propsTable.rowsPerPage,
      orderCode: '',
      transactionCode: '',
      giftCode: '',
      id: '',
      deliveryCode: '',
      returnDate: '',
      keyword: '',
    });
  };

  const { control, handleSubmit, reset } = methods;

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ px: 2 }}>
          <Grid container spacing={3} py="20px">
            <Grid item xs={4} sx={{ marginBottom: '15px' }}>
              <Controller
                name="keyword"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Search by ID, Recipient name, ...'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={'20px'}>
                <Controller
                  name="createdDate"
                  key="createdDate"
                  control={control}
                  render={({ field }) => (
                    <MobileDateTimePicker
                      {...field}
                      label="Ngày hoàn xu"
                      key="createdDate"
                      inputFormat={FORMAT_DATE_NEWS}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar />
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Stack
              direction="row"
              spacing={3}
              marginTop="10px"
              mx={2}
              alignItems="center"
            >
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                sx={{ height: '40px', padding: '4px 12px' }}
              >
                Tìm kiếm
              </LoadingButton>
              <Button
                type="reset"
                variant="contained"
                color="inherit"
                size="small"
                onClick={handleResetSearch}
                sx={{ height: '40px', padding: '4px 12px' }}
              >
                Xóa
              </Button>
            </Stack>
          </Grid>
        </Card>
      </FormProvider>
    </>
  );
};

export default CoinListFilter;
