import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

export default function HiddenTagEditHeader() {
  const params = useParams();
  const id = params?.id;
  return (
    <>
      <HeaderBreadcrumbs
        heading={id ? BREADCUMBS.HIDDEN_TAG_EDIT : BREADCUMBS.HIDDEN_TAG_CREATE}
        links={[
          {
            name: 'Danh sách user tag',
            href: PATH_DASHBOARD.hiddenTagsAdmin.root,
          },
          { name: id ? 'Chỉnh sửa user tag' : 'Tạo user tag' },
        ]}
      />
    </>
  );
}
