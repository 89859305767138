// routes article
/* eslint no-use-before-define: 2 */ // --> ON
export const API_BANNER = '/api/banner';
export const API_PRESIGNED = '/api-v3/loyalty/v1/admin/file/presigned-url';
export const API_PRESIGN_URL = '/api-v3/loyalty/v1/admin/file/presigned-url';
export const API_ARTICLE_ADMIN = '/api/admin/article';
export const API_CATEGORY_ADMIN = '/api/admin/category';
export const API_EVENT = '/api/events';
export const API_EVENT_ADMIN = '/api/admin/events';
export const API_REGISTER_EVENT_ADMIN = '/api/admin/events/register';
export const API_DOCUMENT = '/api/admin/documents';
export const API_PRODUCT = '/api/admin/product/all';
export const API_UPDATE_EVENT_STATUS = '/api/admin/events';
export const API_LOGIN = '/api/admin/login';
export const API_LOGOUT = '/api/admin/logout';
export const API_REGISTER = '/api/account/register';
// -----------------
export const API_SHOP_INVITATION = '/api/admin/store-invitation';
export const API_SHOP_INVITATION_EXPORTCSV = '/api/admin/store-invitation/export/csv';
export const API_ADMIN = '/api/admin';
export const API_STORE_ADMIN = '/api/admin/store';
export const API_STORE_ADMIN_EXPORT = '/api/admin/store/export/csv';
export const API_STORE_ADMIN_CODE = '/api/admin/store';
export const API_ADMIN_FORGOT_PASSWORD = '/api/admin/forgot-password';
export const API_PRIZE_HISTORY = '/api/admin/event-point-history';
export const API_LIST_PRiZE = '/api/admin/events/details';
export const API_CREATE_TRANSACTION_TYPE =
  '/api/admin/crm-transaction-type/event-quarter-4-unused';
export const API_TRANSACTION_TYPE = '/api/admin/crm-transaction-type';
export const API_TRANSACTION_TYPE_UNUSE =
  '/api/admin/crm-transaction-type/event-quarter-4-unused';
export const API_PROVINCE = '/api/province';
export const API_CREATE_EVENT = '/api/admin/events/details';
export const API_PRIZE_EDIT = '/api/admin/events/details';
export const API_EDIT_EVENT_PRIZE = '/api/admin/events/details';
export const API_PROVINCE_VN = '/api/province/vn';
export const API_PROVINCE_SEARCH_BY_FILTER = '/api/province/search_by_filter';
export const API_GET_ALL_GIFTS = '/api/admin/gift';
export const API_GIFT = '/api/admin/gift';

export const API_EVENT_Q1_PRIZE = '/api-v3/loyalty/v1/admin/event-detail';
//  EVENT Q1
export const API_EVENT_Q1_ADMIN = '/api-v3/loyalty/v1/admin/event';
export const API_EVENT_GROUP_ADMIN = '/api-v3/loyalty/v1/admin/event-group';
export const API_PRIZE_HISTORY_Q1 = '/api-v3/loyalty/v1/admin/event-point-history';
export const API_EVENT_WEBVIEW = '/api-v3/loyalty/v1/event-detail';
export const API_EVENT_GIFT_WEBVIEW = '/api/gift';

export const API_MANAGE_EVENT = '/api-v3/loyalty/v1/admin/event';
export const API_EVENT_GROUP = '/api-v3/loyalty/v1/admin/event-group';
export const API_Q1_GET_ALL_GROUP_EVENT = '/api-v3/loyalty/v1/admin/event-group';
export const API_Q1_GET_ALL_EVENT_NOT_IN_GROUP =
  '/api-v3/loyalty/v1/admin/event/not-in-group';
export const API_GET_LIST_SKU =
  '/api-v3/loyalty/v1/admin/event-detail/event-product-child';

export const API_EVENT_ADD_ON = '/api-v3/loyalty/v1/admin/event-add-can';
export const API_GET_GIFT_CATEGORY =
  '/api-v3/loyalty/v1/admin/gift/gift-category/not-in-event-add-can';
// event Q4
export const API_EVENT_Q4_CLS = '/api-v3/loyalty/v1/user/event-add-can/number-of-can';
export const API_USER_EVENT = '/api-v3/loyalty/v1/user/event';

// Notification
export const API_NOTI_CAMPAIGN_CONDITION = '/api-v3/noti/admin/noti-campaign-condition';
export const API_NOTI_CAMPAIGN_CONFIG_RULE =
  '/api-v3/noti/admin/noti-campaign-config-rule';
export const API_NOTI_DISPLAY_TEMPLATE = '/api-v3/noti/admin/noti-display-template';
export const API_NOTI_CAMPAIGN = '/api-v3/noti/admin/noti-campaign';
export const API_GROUP_USER = '/api-v3/user/admin/user-group';
export const API_RANK = '/api/admin/tier/list';
export const API_LIST_EVENT_CAMPAIGN = '/api/admin/crm-transaction-type';

// event Q1
export const API_CAN_MARK_USER =
  '/api-v3/loyalty/v1/user/event-can-mark/gifts-number-scan';
export const API_INFO_USER = '/api-v3/loyalty/v1/user';
export const API_EVENT_DETAIL = '/api-v3/loyalty/v1/event-detail';
export const API_GIFT_WITH_AUTH = '/api/gift/with-auth';

// user-Management
export const API_GET_LIST_USER_GROUP_TO_USER = '/api-v3/user/admin/user-group-to-user';
export const API_ADD_USER_GROUP_USER = '/api-v3/user/admin/user-group-to-user/add';
export const API_POST_IMPORT_FILE_GROUP_USER =
  '/api-v3/user/admin/user-group-to-user/request-import';
export const API_DELETE_USER_FROM_GROUP_USER =
  '/api-v3/user/admin/user-group-to-user/remove';
export const API_GET_ALL_USER = '/api/admin/user/all';
export const API_GET_ALL_USER_NEW = '/api-v3/user/admin/user';
export const API_EXCHANGE_PHONE_NUMBER =
  '/api-v3/loyalty/v1/user/user-phone-mismatch/transform';
export const API_SYSTEM_CONFIG = '/api-v3/loyalty/v1/admin/system-config-v2';

export const API_GIFT_V2 = '/v4/gs/admin/gifts';
export const API_CATEGORY_FILTER_ADMIN_V2 = '/v4/gs/admin/gift-categories';
export const API_CATEGORY_ADMIN_V2 = '/api/admin/gift-category';
export const API_BRAND_GOTIT_V2 = '/api/admin/got-it/brand';
export const API_CATEGORY_GOTIT_V2 = '/api/admin/got-it/category';
export const API_PRODUCT_GOTIT_V2 = '/api/admin/got-it/product';
export const API_COURSES_BKID_V2 = '/api-v3/loyalty/v1/admin/gift/bkids/courses';
export const API_LIST_BADGES_V2 = '/v4/gs/admin/gift-badges';
export const API_ORDER_V2 = '/v4/os/orders';
export const API_ORDER_GIFTS_V2 = '/v4/os/orders/gifts';
export const API_GIFT_CATEGORIES_ADMIN_V3 = '/v4/gs/admin/gift-categories';
export const API_HIDDEN_TAGS_ADMIN_V3 = '/v4/gs/admin/hidden-tags';
export const API_FILTER_TAGS_ADMIN_V3 = '/v4/gs/dynamic-filter';
export const API_LIST_DYNAMIC_FILTER_V2 = '/v4/gs/dynamic-filter';
export const API_GIFT_PRE_ORDERS_ADMIN_V3 = '/v4/gs/admin/user-gifts';


// coin-Mangement
export const API_COIN_LIST = 'v4/os/points/history-return-point';
export const API_COIN_IMPORT = 'v4/os/orders/import/return-point';
