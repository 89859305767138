import dayjs from 'dayjs';
import { helperText } from 'src/common/constants/common.constants';
import * as yup from 'yup';

export const giftFormSchema = yup.object().shape({
  rewardAppName: yup.string().required(helperText.required),
  categoryId: yup.string().required(helperText.required),
  status: yup.boolean().required(helperText.required),
  sourceGift: yup.string(),
  giftType: yup.string().required(helperText.required),
  // selectGift: yup.mixed().when('giftType', {
  //   is: (giftType: string) => giftType !== 'GIFT' && giftType !== 'EV_VITA_CODE',
  //   then: yup.mixed().required(helperText.required),
  //   otherwise: yup.mixed().nullable(),
  // }),
  selectGift: yup.mixed().nullable(),
  tierCodes: yup
    .array()
    .of(yup.string())
    .required(helperText.required)
    .min(1, helperText.required),
  rewardAppGiftCode: yup.string().required(helperText.required),
  priority: yup.string().min(0, helperText.min),
  point: yup.string().required(helperText.required).min(0, helperText.min),
  money: yup.string().required(helperText.required).min(0, helperText.min),
  smsGiftId: yup.string().optional(),
  startDate: yup
    .string()
    .required(helperText.required)
    .nullable()
    .test(
      'is-startDate-before-endDate',
      'Ngày bắt đầu phải <= Ngày kết thúc',
      function (value) {
        const { endDate } = this.parent; // Lấy giá trị của endDate từ cùng một object
        if (!value || !endDate) return true; // Nếu một trong hai giá trị không tồn tại thì không cần kiểm tra
        return (
          dayjs(value).isBefore(dayjs(endDate)) || dayjs(value).isSame(dayjs(endDate))
        ); // Kiểm tra startDate <= endDate
      }
    ),
  endDate: yup.string().required(helperText.required).nullable(),
  activeSmsNotification: yup.boolean().required(helperText.required),
  codeSCT: yup.string().required(helperText.required),
  total: yup.string(),
  totalBucket: yup.string(),
  limitGiftCount: yup.string(),
  coverFiles: yup
    .array()
    .of(yup.mixed().required(helperText.required))
    .min(0, helperText.required),
  imageFile: yup.mixed().required(helperText.required),
  weight: yup.string().when('giftType', {
    is: (giftType: string) => giftType === 'GIFT',
    then: yup.string().required(helperText.required),
    otherwise: yup.string().notRequired(),
  }),
  length: yup.string().when('giftType', {
    is: (giftType: string) => giftType === 'GIFT',
    then: yup.string().required(helperText.required),
    otherwise: yup.string().notRequired(),
  }),
  width: yup.string().when('giftType', {
    is: (giftType: string) => giftType === 'GIFT',
    then: yup.string().required(helperText.required),
    otherwise: yup.string().notRequired(),
  }),
  height: yup.string().when('giftType', {
    is: (giftType: string) => giftType === 'GIFT',
    then: yup.string().required(helperText.required),
    otherwise: yup.string().notRequired(),
  }),
  covers: yup.array().of(yup.string()),
  expiredDate: yup.string().nullable(),
  expiredTime: yup.string(),
  description: yup.string(),
  image: yup.string(),
  tagId: yup.string(),
  transportType: yup.string().when('giftType', {
    is: (giftType: string) => giftType === 'GIFT',
    then: yup.string().required(helperText.required),
    otherwise: yup.string().notRequired(),
  }),
  maximumReservationQuantity: yup
    .number()
    .when('isAllowReservation', {
      is: (isAllowReservation: boolean) => isAllowReservation,
      then: yup
        .number()
        .required(helperText.required)
        .typeError('Phải là số dương')
        .min(0, 'Phải là số dương'),
      otherwise: yup
        .number()
        // .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
        .nullable(),
    })
    .when('total', (total, schema) =>
      schema.max(parseInt(total, 10), 'SL đặt quá số lượng total')
    ),
  allowReservationInCoin: yup.number().when('isAllowReservation', {
    is: (isAllowReservation: boolean) => isAllowReservation,
    then: yup
      .number()
      .required(helperText.required)
      .typeError('Phải là số dương')
      .min(0, 'Phải là số dương'),
    otherwise: yup
      .number()
      // .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable(),
  }),
  reservationPeriodDays: yup.number().when('isAllowReservation', {
    is: (isAllowReservation: boolean) => isAllowReservation,
    then: yup.number().typeError('Phải là số dương').min(0, 'Phải là số dương'),
    otherwise: yup
      .number()
      // .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable(),
  }),
  limitReservationTime: yup.number().when('isAllowReservation', {
    is: (isAllowReservation: boolean) => isAllowReservation,
    then: yup.number().typeError('Phải là số dương').min(0, 'Phải là số dương'),
    otherwise: yup
      .number()
      // .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable(),
  }),
  discountReservation: yup.number().when('isAllowReservation', {
    is: (isAllowReservation: boolean) => isAllowReservation,
    then: yup.number().typeError('Phải là số dương').min(0, 'Phải là số dương'),
    otherwise: yup
      .number()
      // .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable(),
  }),
  coinToMinus: yup.number().when('isAllowReservation', {
    is: (isAllowReservation: boolean) => isAllowReservation,
    then: yup
      .number()
      .required(helperText.required)
      .typeError('Phải là số dương')
      .min(0, 'Phải là số dương'),
    otherwise: yup
      .number()
      // .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable(),
  }),
  dynamicFilter: yup
    .object()
    .shape({
      code: yup.string().nullable().optional(),
      valueCode: yup.string().nullable().optional(),
      firstLevelCode: yup.string().nullable().optional(),
      firstLevelValueCode: yup.string().nullable().optional(),
      secondLevelCode: yup.string().nullable().optional(),
      secondLevelValueCode: yup.string().nullable().optional(),
    })
    .nullable()
    .optional(),
});

export const giftCategoryFormSchema = yup.object().shape({
  name: yup.string().required(helperText.required),
  code: yup
    .string()
    .required(helperText.required)
    .test('not-only-spaces', 'Giá trị không được chỉ có dấu cách', (value) => {
      return value ? value?.trim().length > 0 : false;
    }),
  priority: yup.number().typeError('Phải là số dương').min(1, 'Phải là số lớn hơn 0'),
});
