export const QUERY_KEYS = Object.freeze({
  CATEGORY_LIST_POLICY: 'CATEGORY_LIST_POLICY',
  CATEGORY_LIST_ENTERPRISE: 'CATEGORY_LIST_ENTERPRISE',
  CATEGORY_LIST: 'CATEGORY_LIST',
  CATEGORY_DETAIL: 'CATEGORY_DETAIL',
  ARTICLE_LIST: 'ARTICLE_LIST',
  EVENT_LIST: 'EVENT_LIST',
  DELETE_EVENT_LIST: 'DELETE_EVENT_LIST',
  EDIT_EVENT_ADMIN: 'EDIT_EVENT_ADMIN',
  EVENT_PRIZE_HISTORY: 'EVENT_PRIZE_HISTORY',
  EVENT_PRIZE_HISTORY_Q1: 'EVENT_PRIZE_HISTORY_Q1',
  EVENT_DETAIL: 'EVENT_DETAIL',
  REGISTER_EVENT: 'REGISTER_EVENT',
  DOCUMENT_LIST: 'DOCUMENT_LIST',
  PRODUCT_CODE: 'PRODUCT_CODE',
  ADMIN: 'ADMIN_LIST',
  ADD_NEW_ADMIN: 'ADD_NEW_ADMIN',
  EDIT_ADMIN: 'EDIT_ADMIN',
  SHOP_INVITATION_LIST: 'SHOP_INVITATION_LIST',
  SHOP_INVITATION_EXPORTCSV: 'SHOP_INVITATION_EXPORTCSV',
  STORE_ADMIN: 'STORE_ADMIN',
  STORE_ADMIN_EXPORT: 'STORE_ADMIN_EXPORT',
  STORE_ADMIN_EDIT: 'STORE_ADMIN_EDIT',
  EVENT_LIST_PRIZE: 'EVENT_LIST_PRIZE',
  EVENT_CREATE_PRIZE: 'EVENT_CREATE_PRIZE',
  EVENT_GET_TRANSACTION: 'EVENT_GET_TRANSACTION',
  EVENT_GET_PROVINCE: 'EVENT_GET_PROVINCE',
  EVENT_PRIZE_DETAIL: 'EVENT_PRIZE_DETAIL',
  ALL_TRANSACTION_TYPE: 'ALL_TRANSACTION_TYPE',
  ALL_PROVINCE_VN: 'ALL_PROVINCE_VN',
  LIST_GIFT: 'LIST_GIFT',
  GIFT_DETAIL: 'GIFT_DETAIL',
  EVENT_EDIT_DETAIL: 'EVENT_EDIT_DETAIL',
  EVENT_LIST_PRIZE_Q1: 'EVENT_LIST_PRIZE_Q1',

  // EVENT Q1
  EDIT_EVENT_Q1_ADMIN: 'EDIT_EVENT_Q1_ADMIN',
  EVENT_GROUP: 'EVENT_GROUP',
  EVENT_Q1_LUCK_HITS_GOLD: 'EVENT_LUCK_HITS_GOLD',

  EVENT_ADMIN_CONTROLLER: 'EVENT_ADMIN_CONTROLLER',
  EVENT_LIST_GROUP_EVENT: 'EVENT_LIST_GROUP_EVENT',
  EVENT_NOT_IN_GROUP: 'EVENT_NOT_IN_GROUP',
  EDIT_GROUP_EVENT: 'EDIT_GROUP_EVENT',
  GROUP_EVENT_BY_ID: 'EVENT_BY_ID',
  LIST_SKU_EVENT: 'LIST_SKU_EVENT',
  LIST_EVENT_ADD_CAN: 'LIST_EVENT_ADD_CAN',
  LIST_GIFT_WITH_AUTH: 'LIST_GIFT_WITH_AUTH',

  // event Q4
  EVENT_Q4_CLS: 'EVENT_Q4_CLS',
  EVENT_Q1_24: 'EVENT_Q1_24',
  GET_INFO_CUSTOMER: 'GET_INFO_CUSTOMER',

  LIST_CAMPAIGN: 'LIST_CAMPAIGN',
  NOTIFY_CONDITION: 'NOTIFY_CONDITION',
  NOTIFY_CONFIG_RULE: 'NOTIFY_CONFIG_RULE',
  LIST_GROUP_USER: 'LIST_GROUP_USER',
  LOCATION: 'LOCATION',
  LIST_USER: 'LIST_USER',
  CAMPAIGN_BY_ID: 'CAMPAIGN_BY_ID',
  GROUP_USER_BY_ID: 'GROUP_USER_BY_ID',
  LIST_RANK: 'LIST_RANK',
  LIST_EVENT_JOINED: 'LIST_EVENT_JOINED',

  LIST_USER_GROUP: 'LIST_USER_GROUP',
  USER_GROUP_BY_ID: 'USER_GROUP_BY_ID',
  CREATE_GROUP_USER: 'CREATE_GROUP_USER',
  EDIT_GROUP_USER: 'EDIT_GROUP_USER',
  IMPORT_USER_TO_GROUP_USER: 'IMPORT_USER_TO_GROUP_USER',
  LIST_SYSTEM_CONFIG: 'LIST_SYSTEM_CONFIG',
  SYSTEM_CONFIG_BY_ID: 'SYSTEM_CONFIG_BY_ID',
  BANNER_LIST: 'BANNER_LIST',
  BANNER_BY_ID: 'BANNER_BY_ID',
  GIFT_LIST: 'GIFT_LIST',
  GIFT_BY_ID: 'GIFT_BY_ID',
  ORDER_LIST: 'ORDER_LIST',
  ORDER_EDIT_LIST: 'ORDER_EDIT_LIST',
  ORDER_IMPORT_UPDATE: 'ORDER_IMPORT_UPDATE',
  ORDER_IMPORT_REDELIVERY: 'ORDER_IMPORT_REDELIVERY',
  ORDER_IMPORT_DELIVERY_PROVIDER: 'ORDER_IMPORT_DELIVERY_PROVIDER',
  ORDER_IMPORT_ORDER_GIFT: 'ORDER_IMPORT_ORDER_GIFT',
  ORDER_IMPORT_PART_DELIVERY: 'ORDER_IMPORT_PART_DELIVERY',
  GIFT_CATEGORY_LIST: 'GIFT_CATEGORY_LIST',
  GIFT_CATEGORY_BY_ID: 'GIFT_CATEGORY_BY_ID',
  HIDDEN_TAG_LIST: 'HIDDEN_TAG_LIST',
  HIDDEN_TAG_BY_ID: 'HIDDEN_TAG_BY_ID',
  GIFT_PRE_ORDER_LIST: 'GIFT_PRE_ORDER_LIST',

  // coin-Management
  COIN_LIST: 'COIN_LIST',
  COIN_EDIT_LIST: 'COIN_LIST',
  COIN_IMPORT: 'COIN_IMPORT',
});
