import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import { BREADCUMBS } from 'src/common/constants/common.constants';

export default function GiftPreOrderListHeader() {
  return (
    <>
      <HeaderBreadcrumbs heading={BREADCUMBS.GIFT_PRE_ORDER} action={<></>} />
    </>
  );
}
