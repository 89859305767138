import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import FilterEditHeader from './components/FilterEditHeader';
import FilterEditForm from './components/FilterEditForm';

export default function FilterEdit() {
  const { themeStretch } = useSettings();

  return (
    <Page title={BREADCUMBS.FILTER_EDIT}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FilterEditHeader />
        <FilterEditForm />
      </Container>
    </Page>
  );
}
