import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/common/redux/store';
import { ISearchCurrentUser } from './interface';
import { DEFAULT_VALUE_SEARCH_USER } from './constant';

type StateProps = {
  searchForm: ISearchCurrentUser;
  isOpenModalDelete: boolean;
  selectedRow: number[];
  isOpenModalEditUser: boolean;
  listEditCurrentUser: number[];
  groupUserId: number | null;
};

const initialState: StateProps = {
  searchForm: DEFAULT_VALUE_SEARCH_USER,
  isOpenModalDelete: false,
  selectedRow: [],
  isOpenModalEditUser: false,
  listEditCurrentUser: [],
  groupUserId: null,
};

export const editCurrentUserSlice = createSlice({
  name: 'edit-current-user',
  initialState,
  reducers: {
    setSearchForm: (state, action: PayloadAction<ISearchCurrentUser>) => {
      state.searchForm = action.payload;
    },
    setIsOpenModalDelete: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalDelete = action.payload;
    },
    setSelectedRow: (state, action: PayloadAction<number[]>) => {
      state.selectedRow = action.payload;
    },
    setIsOpenModalEditCurrentUser: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalEditUser = action.payload;
    },
    setListEditCurrentUser: (state, action: PayloadAction<number[]>) => {
      state.listEditCurrentUser = action.payload;
    },
    setGroupUserId: (state, action: PayloadAction<number | null>) => {
      state.groupUserId = action.payload;
    },
    resetEditCurrentUser: (state) => {
      state.isOpenModalDelete = initialState.isOpenModalDelete;
      state.isOpenModalEditUser = initialState.isOpenModalEditUser;
      state.listEditCurrentUser = initialState.listEditCurrentUser;
      state.searchForm = initialState.searchForm;
      state.selectedRow = initialState.selectedRow;
      state.groupUserId = initialState.groupUserId;
    },
  },
});

export const {
  setSearchForm,
  setIsOpenModalDelete,
  setSelectedRow,
  resetEditCurrentUser,
  setIsOpenModalEditCurrentUser,
  setListEditCurrentUser,
  setGroupUserId,
} = editCurrentUserSlice.actions;

export const searchFormSelector = (state: RootState) => state.editCurrentUser.searchForm;

export const isOpenModalDeleteSelector = (state: RootState) =>
  state.editCurrentUser.isOpenModalDelete;

export const selectedRowSelector = (state: RootState) =>
  state.editCurrentUser.selectedRow;

export const isOpenModalEditCurrentUserSelector = (state: RootState) =>
  state.editCurrentUser.isOpenModalEditUser;

export const ListEditCurrentSelector = (state: RootState) =>
  state.editCurrentUser.listEditCurrentUser;

export const groupUserIdSelector = (state: RootState) =>
  state.editCurrentUser.groupUserId;

export default editCurrentUserSlice.reducer;
