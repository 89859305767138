export const DEFAULT_VALUE_SEARCH_USER = {
  searchText: '',
  phoneNumber: '',
  email: '',
  tierCode: '',
  accountStatus: '',
};

export const PICK_USER_TABLE_HEAD = [
  // {
  //   id: 'customerId',
  //   label: 'Mã khách hàng',
  //   align: 'left',
  // },
  {
    id: 'name',
    label: 'Họ và tên',
    align: 'left',
  },
  {
    id: 'phoneNumber',
    label: 'Số điện thoại',
    align: 'left',
  },
  {
    id: 'tier',
    label: 'Hạng thành viên',
    align: 'center',
  },
  {
    id: 'birthday',
    label: 'Ngày sinh',
    align: 'center',
  },
  {
    id: 'status',
    label: 'Trạng thái tài khoản',
    align: 'center',
  },
  {
    id: 'scanStatus',
    label: 'Trạng thái tích xu',
    align: 'center',
  },
];

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  NON_ACTIVE = 'NON_ACTIVE',
}

export const USER_STATUS = [
  {
    name: 'Hoạt động',
    value: UserStatus.ACTIVE,
  },
  {
    name: 'Khóa',
    value: UserStatus.NON_ACTIVE,
  },
];

export enum UserRank {
  MEMBER = 'MEMBER',
  TITAN = 'TITAN',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
}

export enum UserGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export const TYPE_GENDER = [
  {
    label: 'Nam',
    value: UserGender.MALE,
  },
  {
    label: 'Nữ',
    value: UserGender.FEMALE,
  },
];
