import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';

import { importCoinList } from '../service';
import { SUCCESS_UPLOAD_CODE } from '../constant';
import { useGetCoinList } from './useGetCoinList';
import useTable from 'src/common/hooks/useTable';
import { IOrderAdminCallback } from 'src/orders/common/interface';

export function useImportCoin(callback: IOrderAdminCallback) {
  const queryClient = useQueryClient();

  return useMutation(importCoinList, {
    onSuccess: async (rs, _variables) => {
      if (rs.data?.response?.code === SUCCESS_UPLOAD_CODE) {
        await queryClient.invalidateQueries([QUERY_KEYS.COIN_IMPORT]);
        callback.onSuccess && callback.onSuccess(rs);
      } else {
        callback.onError && callback.onError(rs);
      }
    },
    onError: (error, _variables) => {
      callback.onError && callback.onError(error);
    },
  });
}
